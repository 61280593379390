import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["output"];

  fetchContent(event) {
    this.outputTarget.innerHTML = "<div class='pb-5'>" + spinnerInline() + "</div>";
    let page = event.currentTarget.dataset.page;
    let url = event.currentTarget.dataset.paginateLoadUrl;
    let that = this;
    setupAjax();
    $.ajax({
      url: url + "?page=" + page,
      method: "GET",
      dataType: "json",
      success: function (data) {
        that.outputTarget.innerHTML = data.content;      
      },
      error: function (data) {
        that.outputTarget.innerHTML = "<p class='text-center p-6'>Could not load data</p>";
      }
    });
  }
}
