import { Controller } from 'stimulus'

export default class extends Controller {
  connect() {
    let url = '/customer/dashboard/top_freelancers'

    setupAjax();
    $.ajax({ url: url, method: "GET" })
  }
}
