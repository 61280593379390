import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["chart"];

  connect() {
    this.chart = new ApexCharts(this.chartTarget, this.emptyChart());
    this.chart.render();

    this.loadData();
  }

  // Gets chart data for employee
  loadData() {
    let that = this;
    setupAjax();
    $.ajax({
      url: "/employees/employee_reliability_score",
      method: "GET",
      dataType: "json",
      data: {
        employee: {
          slug: that.chartTarget.dataset.employeeid,
        },
      },
      success: function (data) {
        that.getOptions(data);
      },
    });
  }

  // Determines color of chart bar based on score and orders
  getBarColor(score, orders) {
    let that = this;
    if (orders < 5) {
      that.addTitle(orders);
      return "#878787";
    }

    if (score >= 90) {
      return "#007B40";
    } else if (score >= 80 && score < 90) {
      return "#01C868";
    } else if (score >= 70 && score < 80) {
      return "#FFC700";
    } else if (score >= 60 && score < 70) {
      return "#FF8601";
    } else if (score >= 50 && score < 60) {
      return "#FF3400";
    } else {
      return "#BC2300";
    }
  }

  // Displays chart title depending on user
  addTitle(orders) {
    let message = "";
    if (this.chartTarget.dataset.iscustomer == "true") {
      message = "Still being calculated!";
    } else {
      message = [
        "Unlock your Reliability Score",
        "by completing " + (5 - orders) + " orders!",
      ];
    }

    let title = {
      title: {
        text: message,
        align: "center",
        margin: 0,
        offsetX: 0,
        offsetY: 100,
        floating: true,
        style: {
          fontSize: "12px",
          fontWeight: "bold",
          color: "#263238",
        },
      },
    };

    this.chart.updateOptions(title);
  }

  // Determines text color based on amount of orders
  getTextColor(orders) {
    if (orders < 5) return "#878787";

    return "#111";
  }

  // Displays an empty chart as placeholder
  emptyChart() {
    let options = {
      series: [0],
      chart: {
        height: 200,
        width: 190,
        type: "radialBar",
        toolbar: {
          show: false,
        },
      },
      plotOptions: {
        radialBar: {
          startAngle: -95,
          endAngle: 95,
          hollow: {
            margin: 0,
            size: "75%",
            background: "#fff",
            position: "front",
          },
          track: {
            background: "#e8eaed",
            strokeWidth: "67%",
            margin: 0,
          },
          dataLabels: {
            show: true,
            name: {
              offsetY: -25,
              show: true,
              color: "111",
              fontSize: "12px",
              fontWeight: 400,
            },
            value: {
              formatter: function (val) {
                return "";
              },
              offsetY: -10,
              color: "111",
              fontSize: "16px",
              show: true,
              fontWeight: 700,
            },
          },
        },
      },
      fill: {
        colors: ["111"],
      },
      stroke: {
        lineCap: "round",
      },
      labels: [""],
    };

    return options;
  }

  // Loads chart data
  getOptions(chart_data) {
    let score = 0;
    let chartMessage = "";
    let value = 0;

    if (chart_data.orders < 5) {
      chartMessage = "/5";
      score = (chart_data.orders / 5) * 100;
      value = chart_data.orders;
    } else {
      chartMessage = "%";
      score = chart_data.score;
      value = chart_data.score;
    }

    let options = {
      series: [score],
      chart: {
        height: 200,
        width: 190,
        type: "radialBar",
        toolbar: {
          show: false,
        },
      },
      plotOptions: {
        radialBar: {
          startAngle: -95,
          endAngle: 95,
          hollow: {
            margin: 0,
            size: "75%",
            background: "#fff",
            position: "front",
          },
          track: {
            background: "#e8eaed",
            strokeWidth: "67%",
            margin: 0,
          },
          dataLabels: {
            show: true,
            name: {
              offsetY: -25,
              show: true,
              color: this.getTextColor(chart_data.orders),
              fontSize: "12px",
              fontWeight: 400,
            },
            value: {
              formatter: function (val) {
                return value + chartMessage;
              },
              offsetY: -10,
              color: this.getTextColor(chart_data.orders),
              fontSize: "16px",
              show: true,
              fontWeight: 700,
            },
          },
        },
      },
      fill: {
        colors: [this.getBarColor(score, chart_data.orders)],
      },
      stroke: {
        lineCap: "round",
      },
      labels: ["Reliability Score"],
    };

    this.chart.updateOptions(options);
  }
}
