import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "name", "nameInput", "users", "usersInput", "addLabel" ]
  
  connect() {
    console.log("connected to Create Channel");
    this.PRIVATE = 0;
    this.GROUP = 1;
    this.labelChild = '';
  }

  load(event) {
    var cType = $(event.target).val();

    if (cType == this.GROUP) {
      this.nameInputTarget.disabled = false;
      this.nameTarget.classList.remove("d-none");
      this.usersTarget.setAttribute("multiple", true);
      this.labelChild = this.addLabelTarget.appendChild(this.createTextMuted());
    } else {
      this.nameInputTarget.disabled = true;
      this.nameTarget.classList.add("d-none");
      this.usersTarget.removeAttribute("multiple");
      this.addLabelTarget.removeChild(this.labelChild);
    }
  }

  submit() {
    var target = this.usersTarget;
    var users = [];
    for (var i = 0; i < target.length; i++) {
      if (target.options[i].selected) {
        users.push(target.options[i].value);
      }
    }

    console.log(users);
    this.usersInputTarget.value = users;
  }

  createTextMuted() {
    var span = document.createElement("span");
    span.classList.add("text-muted");
    span.innerHTML = "CTRL + Click";

    return span;
  }
  
}
