import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "users", "usersInput", "addLabel", "id" ]
  
  connect() {
    console.log("connected to Add Channel Member");
    this.PRIVATE = 0;
    this.GROUP = 1;
    this.labelChild = '';
  }

  submit() {
    var usersTarget = this.usersTarget;
    var users = [];
    for (var i = 0; i < usersTarget.length; i++) {
      if (usersTarget.options[i].selected) {
        users.push(usersTarget.options[i].value);
      }
    }

    this.usersInputTarget.value = users;

    this.idTarget.value = $("#message-tab-pane").data("id");

    let form = document.getElementById("channel-add-user-form");

    if (form) {
      form.submit();
    }
  }
}
