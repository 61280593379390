import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = []

  connect() {
    // console.log('conversation-listener-component controller connected');
    const token = this.element.getAttribute("data-token");

    this.currentAuthorId = this.element.getAttribute("data-current-author");
    // don't send alerts on these paths
    this.noMessagePaths = ["/conversations"];

    if (window.twilioClient) {
      this.clientActions(window.twilioClient);
    } else {
      Twilio.Conversations.Client.create(
        token, { reachabilityEnabled: true }
      ).then(client => {
        window.twilioClient = client;
        this.clientActions(window.twilioClient);
      });
    }
  }

  clientActions(client) {
    this.twilioClient = client;

    client.getSubscribedConversations().then(response => {
      response.items.forEach(conversation => {
        conversation.on("messageAdded", (message) => {
          if (this.onMessagePage()) {
            return
          }
          if (this.isCurrentAuthor(message.author)) {
            return
          }

          // currently handle notifications from the Rails side
          // (webhook after conversationMesage creation)
          //
          // this.notifyBrowser(message)
          return null
        })
      });
    });      

    // -- Other potential Twilio Events --
    //
    // client.on("tokenAboutToExpire", (data) => {
    //   console.log(`tokenAboutToExpire: ${data}`);
    // });
    // client.on("conversationAdded", (data) => {
    //   console.log(`conversationAdded': ${data}`);
    // });
    // client.on("conversationJoined", (data) => {
    //   console.log(`conversationJoined: ${data}`);
    // });
    // client.on("conversationLeft", (data) => {
    //   console.log(`conversationLeft: ${data}`);
    // });
    // client.on("connectionStateChanged", (state) => {
    //   console.log(`connectionStateChanged: ${state}`);
    // });
  }

  notifyBrowser(message) {
    let notificationJson = this.formatConversation(message);
    $(document).trigger('conversation-listener.state', notificationJson);
  }

  isCurrentAuthor(author) {
    return author === this.currentAuthorId;
  }

  onMessagePage() {
    if (this.noMessagePaths.includes(window.location.pathname)
      || window.chatPopupOpen) {
      return true
    } else {
      return false;
    }
  }

  formatConversation(message) {
    return {
      notifications: [{
        "id": message.sid,
        "object_url": "/conversations",
        "object_id": message.conversation.uniqueName,
        "message": message.body,
        "notification_type": "user_messages",
        "sender_name": "",
        "description_message": "New direct message",
        "date_time": message.dateCreated.toLocaleTimeString(),
        "sort_type": null,
        "avatar_url": null,
      }],
      last_notification_time: null,
      status: 200
    }
  }
}
