import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [
    "startDate",
    "endDate",
    "chart",
    "postingsStarted",
    "postingsLive",
    "postingsPaid",
    "postingsWaitingPayment"
  ]

  connect() {
    console.log("connected to Customer Interactions");

    this.chart = new ApexCharts(this.chartTarget, this.getOptions());
    this.chart.render();

    this.loadData();
  }

  /**
   * Loads the Interactions data to be displayed
   *
   */
  loadData() {
    let startDate = this.startDateTarget.value;
    let endDate = this.endDateTarget.value;

    var that = this;
    setupAjax();
    $.ajax({
      url: "/analytics/customer_interaction/stats",
      method: "POST",
      data: {
        interaction: {
          start_date: startDate,
          end_date: endDate
        }
      },
      dataType: "json",
      success: function (data) {
       that.updateChart(data.chart_data);
       that.updateStats(data.stats);
      }
    });
  }

  /**
   * Updates the stats data to be displayed for the user
   *
   * @param {hash} stats containing the customer interaction data to be displayed
   *    [postingsStarted, postingsLive, postingsPaid, postingsWaitingPayment]
   */
  updateStats(stats) {
    this.postingsStartedTarget.innerHTML = this.getStatHTMLRender(stats[0]);
    this.postingsLiveTarget.innerHTML = this.getStatHTMLRender(stats[1]);
    this.postingsPaidTarget.innerHTML = this.getStatHTMLRender(stats[2]);
    this.postingsWaitingPaymentTarget.innerHTML = this.getStatHTMLRender(stats[3]);
  }

  /**
   * Updates the chart data to be displayed for the user
   *
   * @param {hash} chartData containing the customer interaction data to be displayed
   *    {date: [customerSignups, customerAffiliateSignups, jobPostings]}
   */
  updateChart(chartData) {
    var customerSignups = [];
    var customerAffiliateSignups = [];
    var jobPostings = [];
    var dates = [];

    // Get chart data and populate the relevant arrays to prepare
    // for display
    $.each(chartData, function(key, value) {
      dates.push(key);
      customerSignups.push(value[0]);
      customerAffiliateSignups.push(value[1]);
      jobPostings.push(value[2]);
    });

    this.chart.updateOptions(
      this.getOptions(
        customerSignups,
        jobPostings,
        customerAffiliateSignups,
        dates
      )
    );
  }

  /**
   * Gets the options hash for the ApexChart
   *
   * @param {hash} customerSignups (optional) the data points for customer signups
   * @param {hash} jobPostings (optional) the data points for the number of job postings
   * @param {hash} affiliateCustomerSignups (optional) the data points for affiliate customer signups
   * @param {hash} dates (optional) the data points for the dates, xaxis
   */
  getOptions(customerSignups = [], jobPostings = [], affiliateCustomerSignups = [], dates = []) {
    var options = {
      chart: {
        type: 'bar',
        height: '300px'
      },
      series: [{
        name: 'Customer Signups',
        data: customerSignups
      },
      {
        name: 'Job Postings',
        data: jobPostings
      },
      {
        name: 'Affiliate Customer Signups',
        data: affiliateCustomerSignups
      }],
      xaxis: {
        categories: dates
      }
    }

    return options;
  }

  /**
   * Gets the html render for the stat, to be displayed to user
   *
   * @param {integer} stat the stat for rendering
   */
  getStatHTMLRender(stat) {
    return '<span class="label label-xl label-light label-inline my-lg-0 my-2 text-dark-50 font-weight-bolder">' + stat + '</span>';
  }
}
