import { Controller } from "stimulus"

export default class extends Controller {

  connect() {

  }
  
  channelOptions() {
    var channelId = this.targets.find("channel").dataset.channelId;
    var channelName = this.targets.find("channel").dataset.channelName;
    var channelDisabled = this.targets.find("channel").dataset.channelDisabled;
  
    $("#channelName").html(channelName);
    $("#channel_id").val(channelId);

    if(channelDisabled === "true") {
      $("#unarchive_option").hide();
      $("#disabled_text").show();
    } else {
      $("#unarchive_option").show();
      $("#disabled_text").hide();
    }

    $("#channelOptionsModal").modal("show");
  }

  archiveChannel() {
    var channelId = $("#channel_id").val();

    setupAjax();
    $.ajax({
      url: "/messages/archive_channel",
      method: "PUT",
      data: {
        channel: {
          id: channelId,
        },
      },
    });
  }

  unarchiveChannel() {
    var channelId = $("#channel_id").val();

    setupAjax();
    $.ajax({
      url: "/messages/unarchive_channel",
      method: "PUT",
      data: {
        channel: {
          id: channelId,
        },
      },
    });
  }

  deleteChannel() {
    var channelId = $("#channel_id").val();

    setupAjax();
    $.ajax({
      url: "/messages/delete_channel",
      method: "DELETE",
      data: {
        channel: {
          id: channelId,
        },
      },
    });
  }
}
