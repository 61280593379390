import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [
    "startDate",
    "endDate",
    "discountCode",
    "productName",
    "totalDiscount",
    "totalRevenue"
  ]

  createSalesOutreach(event) {
    let reason = event.currentTarget.dataset.reason;
    let customer = event.currentTarget.dataset.customer;
    let user = event.currentTarget.dataset.user;

    if (!confirm("Confirm customer was contacted.")) { return; }

    setupAjax();
    $.ajax({
      url: "/analytics/sales_customer_outreaches",
      method: "POST",
      data: {
        customer_outreach: {
          contact_reason: reason,
          customer_id: customer,
          contacted_by_id: user
        }
      },
      success: function () {
        $("#outreach-" + customer).remove();
      },
      error: function () {
        location.reload();
      }
    });
  }
}
