import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "search", "resetLink", "path", "name", "results" ];

  initialize() {
    this.searchTags = new Array();
    this.params = $.urlParam("filter");
    this.cloud_tags = ['position-tags', 'tier-tags', 'niche-tags', 'language-tags'];
    this.page = 1;

    if (this.params) {
      // Replace special characters with correct variants
      this.params = this.params.replace(/%20/g, " ");
      this.params = this.params.replace(/\+/g,' ');
      this.params = this.params.replace(/%2C/g, ",");

      this.searchTags = this.params.split(",");

      var then = this;
      this.searchTags.forEach(function(tag) {
        var element = $("#tag-cloud span:contains(" + tag + ")").filter(
          function(){
            return $(this).text() === tag ? true : false;
          }
        )[0];
        element.dataset.active = true;
        then.updateSearch(element);
      });
    }

    if (this.defaultSearchIs("false")) {
      $("#search_by_tag").toggle();
      $("#search_by_name").toggle();
      $(".swap").attr('checked', true);
    }
  }

  connect() {
    this.search();
  }

  search() {
    let path = this.pathTarget.value;
    let tags = this.searchTags.join(",");
    let name = this.nameTarget.value;

    let page = "&page=" + this.page
    let url = '';
    if (name) {
      url = path + "?search=" + name + page;
    } else {
      url = path + "?filter=" + tags + page;
    }

    this.resultsTarget.innerHTML = spinnerInline();
    Rails.ajax({
      type: "GET",
      url: url,
      success: results => {
        this.configurePagination();
      },
      error: err => {
        console.log('Unable to load results');
        this.resultsTarget.innerHTML = "<div class='text-danger'>Unable to load results, please contact support@dibbly.com</div>";
      }
    });
  }

  configurePagination() {
    let elements = document.querySelectorAll('a.page-link');

    for (let i = 0; i < elements.length; i++) {
      elements[i].href = '#';
      elements[i].setAttribute("data-action", "team-search#setPage");
    }
  }

  filter() {
    this.page = 1;
    this.search();
  }

  setPage(event) {
    event.stopPropagation();
    event.preventDefault();

    let target = event.target;

    if (target.rel == 'next') {
      this.page = parseInt(this.page) + 1;
    } else if (target.rel == 'prev') {
      this.page = parseInt(this.page) - 1;
    } else {
      this.page = target.innerHTML;
    }

    this.search();
  }

  reset() {
    var url = this.resetLinkTarget.dataset.link;
    window.location = url;
  }

  toggleTag(event) {
    if (event.target.nodeName == 'svg') {
      return
    }

    if (event.target.nodeName == 'path') {
      return
    }

    if (event.target.dataset.toggle != undefined) {
      return
    }

    event.preventDefault();

    // If user miss clicks on empty space instead of taking the link element
    // take the inner span element instead.
    if ($(event.target).is("a")) {
      var element = event.target.children[0];
    } else {
      var element = event.target;
    }

    var active = element.dataset.active;
    var tagId = element.innerHTML.toLowerCase();
    var tag = document.getElementById(tagId);

    if (active === "true") {
      const index = this.searchTags.indexOf(element.innerHTML);
      if (index >= 0) {
        this.searchTags.splice(index, 1);
      }

      if (element.parentElement.parentElement.id === 'position-tags') {
        tag.href = "javascript:void(0)";
      }

      element.dataset.active = false;
    } else {
      this.searchTags.push(element.innerHTML);

      if (element.parentElement.parentElement.id === 'position-tags') {
        tag.href = "#tags";
        this.filterSwitch();
      }

      element.dataset.active = true;
    }

    this.updateSearch(element);
  }

  updateSearch(element) {
    this.searchTarget.value = this.searchTags.join(" | ");
    this.updateClass(element);
  }

  updateClass(element) {
    let active = element.dataset.active;
    let tagCloud = element.parentElement.parentElement;

    if (active === "true") {
      element.classList.remove("label-outline-primary");
      element.classList.add("label-primary");

      if (tagCloud.id !== "niche-tags") {
        tagCloud.children.forEach(tag => {
          if (tag.children[0] !== element) {
            tag.classList.add("disabled");
            tag.children[0].classList.add("label-outline-light");
          }
        });
      }
    } else {
      element.classList.add("label-outline-primary");
      element.classList.remove("label-primary");
      if (tagCloud.id !== "niche-tags") {
        this.enableTags(tagCloud.children, element);
      }
    }

    if(tagCloud.id === 'position-tags') {

      if(element.innerHTML !== 'Editor' && element.innerHTML !== 'Writer') {
        this.disableLanguageTags(active);
      }

      if(element.innerHTML !== 'Writer') {
        if(element.innerHTML === 'Editor') {
          this.disableTierTags(active, ['Translation']);
        } else {
          this.disableTierTags(active, []);
        }
      }

    } else if (tagCloud.id === 'tier-tags') {
      if(element.innerHTML === 'Translation') {
        this.disablePositionTags(active, ['Editor', 'Writer']);
        this.disableNicheTags(active);
      } else {
        this.disablePositionTags(active, ['Writer']);
      }

    } else if (tagCloud.id === 'niche-tags') {
      if (element.innerHTML === 'Translation') {
        this.disablePositionTags(active, ['Editor', 'Writer']);
        this.disableTierTags(active, ['Translation']);
        this.disableNicheTags(active);
      }

    } else if (tagCloud.id === 'language-tags') {
      this.disablePositionTags(active, ['Editor', 'Writer']);
      this.disableTierTags(active, ['Translation']);
      this.disableNicheTags(active);
    }

  }

  clear() {
    var that = this;
    $('#tag-search').val('');
    this.searchTags.forEach(function(tag) {
      var element = $("#tag-cloud span:contains(" + tag + ")");
      element.addClass("label-outline-primary").removeClass("label-primary");
      element[0].dataset.active = false;
    });
    this.searchTags = [];
    // should enable all the tags when clear button is pressed
    that.cloud_tags.forEach(function(val){
      let tag_cloud = document.getElementById(val);
      that.enableTags(tag_cloud.children, null);
    });
  }

  filterSwitch() {
    $('#tags').on('slide.bs.carousel', function(e) {
      switch (e.to) {
          case 0:
            $("#position-tags").show();
            $("#tier-tags").hide();
            $("#niche-tags").hide();
            $("#language-tags").hide();
          break;
          case 1:
            $("#position-tags").hide();
            $("#tier-tags").show();
            $("#niche-tags").hide();
            $("#language-tags").hide();
          break;
          case 2:
            $("#position-tags").hide();
            $("#tier-tags").hide();
            $("#niche-tags").show();
            $("#language-tags").hide();
          break;
          case 3:
            $("#position-tags").hide();
            $("#tier-tags").hide();
            $("#niche-tags").hide();
            $("#language-tags").show();
        }
    });
  }

  searchType() {
    this.nameTarget.value = "";
    $("#search_by_tag").toggle();
    $("#search_by_name").toggle();
    this.defaultSearchIs("false") ? this.setDefaultSearch("true") : this.setDefaultSearch("false");
  }

  setDefaultSearch(is_default) {
    sessionStorage.setItem("default_search", is_default);
  }

  defaultSearchIs(is_default) {
   return sessionStorage.getItem('default_search') == is_default;
  }

  /**
   * this method helps to enable tags under the particular tag cloud such
   * as position or tier or
   * @param tagCloud is the selected tag cloud
   * @param element that are to be ignored from being enabled
   */
  enableTags(tagCloud, element) {
    tagCloud.forEach(tag => {
      if (tag.children[0] !== element) {
        tag.classList.remove("disabled");
        tag.children[0].classList.remove("label-outline-light");
      }
    });
  }

  /**
   * this method helps to disable tags under the particular tag cloud such
   * as position or tier or
   * @param tagCloud is the selected tag cloud
   * @param element that are to be ignored from being disabled
   */
  disableTags(tagCloud, element) {
    if(Array.isArray(element)) {
      tagCloud.children.forEach(tag => {
        if (element.indexOf(tag.children[0]) < 0) {
          tag.classList.add("disabled");
          tag.children[0].classList.add("label-outline-light");
        }
      });
    } else {
      tagCloud.children.forEach(tag => {
        if (tag.children[0] !== element) {
          tag.classList.add("disabled");
          tag.children[0].classList.add("label-outline-light");
        }
      });
    }
  }

  /**
   * this method helps to find and build the html span elements that need to
   * be ignored while disabling the tags
   * @param excepts are the name of the span elements
   * @returns {*[]} array of span elements
   */
  getExceptTags(excepts) {
    let except_tags = [];
    excepts.forEach(function(tag) {
      let except = $("#tag-cloud span:contains('"+ tag +"')")
          .filter(function(i, elm) { return elm.innerHTML === tag } )[0];
      except_tags.push(except);
    });
    return except_tags;
  }

  /**
   * this method helps to find span elements of language tags and disable them
   * @param active is to check whether the selected element is active
   */
  disableLanguageTags(active) {
    let tagCloudLang = document.getElementById('language-tags');

    if (active === 'true') {
      this.disableTags(tagCloudLang, null);
    } else {
      this.enableTags(tagCloudLang.children, null);
    }
  }

  /**
   * this method helps to find span elements of tier tags and disable them
   * @param active is to check whether the selected element is active
   * @param excepts ignores the elements from being disabled
   */
  disableTierTags(active, excepts) {
    let tagCloudTier = document.getElementById('tier-tags');

    if (active === 'true') {
      let except_elems = this.getExceptTags(excepts);

      this.disableTags(tagCloudTier, except_elems);
    } else {
      this.enableTags(tagCloudTier.children, null);
    }
  }

  /**
   * this method helps to find span elements of position tags and disable them
   * @param active is to check whether the selected element is active
   * @param excepts ignores the elements from being disabled
   */
  disablePositionTags(active, excepts) {
    let tagCloudPosition = document.getElementById('position-tags');

    if (active === 'true') {
      let except_elems = this.getExceptTags(excepts);

      this.disableTags(tagCloudPosition, except_elems);
    } else {
      this.enableTags(tagCloudPosition.children, null);
    }
  }

  /**
   * this method helps to find span elements of niche tags and disable them
   * @param active is to check whether the selected element is active
   */
  disableNicheTags(active) {
    let that = this;
    let tagCloudNiche = document.getElementById('niche-tags');

    if (active === 'true') {
      let except_elms = $("#tag-cloud span:contains('Translation')")
          .filter(function(i, elm) { return elm.innerHTML === 'Translation' } )[1];

      that.disableTags(tagCloudNiche, except_elms);
    } else {
      this.enableTags(tagCloudNiche.children, null);
    }
  }
}
