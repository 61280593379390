import { Controller } from "stimulus";

export default class extends Controller {
  static targets = [
    "indicator",
    "indicatorDoc",
    "indicatorCustomerChat",
    "indicatorTeamChat",
    "userBadge",
    "indicatorChannelChat",
  ];

  connect() {
    var customerChat = this.targets.find("customerChat");
    if (customerChat) {
      var type = customerChat.dataset.type;
      var object = customerChat.dataset.object;
      this.updateOpened(type, object, this);
    }
  }

  removeCommentIndicator(event) {
    if (
      parseInt(event.target.dataset.pending) <= 1 ||
      ($("#commentID").val() == "" &&
        $("#disapprove_doc_comment_id").val() == "" &&
        $("#submit_doc_comment_id").val() == "")
    ) {
      this.indicatorTarget.remove();
      this.indicatorDocTarget.remove();
    } else {
      event.target.dataset.pending--;
    }
  }

  disapperChatDot(event) {
    var type = event.target.dataset.type;
    var object = event.target.dataset.object;

    this.updateOpened(type, object, this);
  }

  disapperChannelDot() {
    var channelIndicator = this.targets.find("indicatorChannelChat");
    if (channelIndicator) {
      var type = channelIndicator.dataset.type;
      var object = channelIndicator.dataset.object;
      this.updateOpened(type, object, this);
    }
  }

  updateOpened(type, object, that) {
    setupAjax();
    $.ajax({
      url: "/notifications/update_opened",
      method: "POST",
      data: {
        notification: {
          type: type,
          object: object,
        },
      },
      success: function (data) {
        if (that.hasIndicatorCustomerChatTarget) {
          if (!data.has_unread_user) {
            $("#notif-user-message-badge").hide();
          }
          if (!data.order_has_unread_user) {
            that.indicatorCustomerChatTarget.remove();
          }
        }
        if (that.hasIndicatorTeamChatTarget) {
          if (!data.order_has_unread_team) {
            that.indicatorTeamChatTarget.remove();
          }
          if (!data.has_unread_team) {
            $("#notif-message-badge").hide();
          }
        }
        if (!data.has_unopened_channel_messages) {
          $("#channelIndicator").hide();
        }
      },
    });
  }
}
