import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "form", "voice", "play", "pause", "stop", "speed" ]
  
  connect() {
    console.log("connected to Speech Synthesis");

    this.synthesis = window.speechSynthesis;
    this.voices = [];
    this.setRefreshEvent();
    this.syncVoices();
  }

  /**
   * Stop the content being played if the browser is refreshed 
   * or goes to a new page
   * 
   */
   setRefreshEvent() {
    var that = this;
    window.addEventListener('beforeunload', function (e) {
      that.stop();
    });
  }

  /**
   * Loads the synthesis voice list from SpeechSynthesis.
   * Due to the list not being available as soon as the page is loaded,
   * we create a promise and check to see if the list is populated
   * first before using it.
   * 
   */
  syncVoices() {
    var that = this;
    return new Promise(function (resolve, reject) {
      let id;
      id = setInterval(() => {
        if (typeof that.synthesis !== 'undefined') {
          if (that.synthesis.getVoices().length !== 0) {
            resolve(that.populateVoices());
            clearInterval(id);
          }
        }
      }, 10);
    });
  }

  /**
   * Gets the list of SpeechSynthesis voices and 
   * populates the dropdown menu
   *
   */
  populateVoices() {
    this.voices = this.synthesis.getVoices();

    // Loop through voice list and populate dropdown
    var that = this;
    let language_available = false;

    for (var i = 0; i < that.voices.length; i++) {
      var option = document.createElement('option');

      // Skipping google voices
      if (!that.voices[i].name.includes('Google')) {
        option.textContent = that.voices[i].name + ' (' + that.voices[i].lang + ')';

        if (that.voices[i].default) {
          option.textContent += ' -- DEFAULT';
        }

        option.setAttribute('data-lang', that.voices[i].lang);
        option.setAttribute('data-name', that.voices[i].name);
        that.voiceTarget.appendChild(option);
        language_available = true;
      }
    }

    if (!language_available) {
      let option = document.createElement('option');
      option.textContent += ' -- No Voices Available';
      that.voiceTarget.appendChild(option);
    }
  }

  /**
   * Plays or resumes the SpeechSynthesis. Uses the selected
   * voice from the dropdown input when playing for the first time.
   * Uses the content from the Order through the global variable
   * orderContent - in assets/javascripts/orders/google_data.js 
   *
   */
  play() {
    var that = this;
    if (this.synthesis.paused) {
      that.synthesis.resume();
    } else {
      var utter = new SpeechSynthesisUtterance(orderContent.replace("\v", " "));
      var selectedOption = this.voiceTarget.selectedOptions[0].getAttribute('data-name');

      for (var i = 0; i < that.voices.length; i++) {
        if (that.voices[i].name === selectedOption) {
          utter.voice = that.voices[i];
        }
      }

      utter.pitch = 1;
      utter.rate = this.speedTarget.value;
      this.synthesis.speak(utter);
    }

    
    this.toggleMediaButtons();
  }

  /**
   * Puts the SpeechSynthesis on pause so it can be resumed later
   * 
   */
  pause() {
    this.synthesis.pause();
    this.toggleMediaButtons();
  }

  /**
   * Cancels SpeechSynthesis so a new one can be played
   * 
   */
  stop() {
    this.synthesis.cancel();
    this.toggleMediaButtons();
  }

  /**
   * Toggles the media buttons for display based on current
   * style display state
   * 
   */
  toggleMediaButtons() {
    var playStyle = this.playTarget.style.display === "none" ? "inline-block" : "none"
    var pauseStyle = this.pauseTarget.style.display === "none" ? "inline-block" : "none"
    var stopStyle = this.stopTarget.style.display === "none" ? "inline-block" : "none"

    this.playTarget.style.display = playStyle;
    this.pauseTarget.style.display = pauseStyle;
    this.stopTarget.style.display = stopStyle;
  }
}
