import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [
    "startDate",
    "endDate",
    "discountCode",
    "productName",
    "totalDiscount",
    "totalRevenue"
  ]

  connect() {
    this.loadData();
  }

  /**
   * Loads the Discounts data to be displayed
   *
   */
  loadData() {
    let startDate = this.startDateTarget.value;
    let endDate = this.endDateTarget.value;
    let discountCode = this.discountCodeTarget.value;
    let productName = this.productNameTarget.value;

    var that = this;
    setupAjax();
    $.ajax({
      url: "/analytics/discounts/stats",
      method: "POST",
      data: {
        discounts: {
          start_date: startDate,
          end_date: endDate,
          discount_code: discountCode,
          product_name: productName
        }
      }
    });
  }
}
