import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [
    'tier', 'fiction', 'nonFiction', 'niches', 'fictionCheckBox', 'discountCode',
    'discountValue', 'discountType', 'discountError', 'discountDollarSymbol', 'discountPercentSymbol',
    'oldRates'
  ]

  connect() {
    this.tierChanged()
    this.fictionChanged()
  }

  fictionChanged() {
    if (this.fictionCheckBoxTarget.checked) {
      this.nonFictionTarget.style.display = 'none'
      this.nonFictionTarget.querySelectorAll('select').forEach(e => e.required = false)
      this.fictionTarget.style.display = 'block'
      this.fictionTarget.querySelectorAll('select').forEach(e => e.required = true)
    } else {
      this.nonFictionTarget.style.display = 'block'
      this.nonFictionTarget.querySelectorAll('select').forEach(e => e.required = true)
      this.fictionTarget.style.display = 'none'
      this.fictionTarget.querySelectorAll('select').forEach(e => e.required = false)
    }

    this.changeNiches()
    this.updatePricing()
  }

  tierChanged() {
    this.changeNiches()
    this.updatePricing()
  }

  changeNiches() {
    let key = this.tierTarget.innerText
    let data = JSON.parse(this.nonFictionTarget.dataset.niches)
    let list;

    if (data.hasOwnProperty(key)) {
      list = data[key]
    } else {
      list = data['Other']
    }

    this.nichesTarget.options.forEach(e => {
      if (list.includes(e.value) || e == this.nichesTarget.options[0]) {
        e.disabled = false
      } else {
        e.disabled = true
      }
    })

    this.nichesTarget.selectedIndex = 0
  }

  addDiscount() {
    let value;
    let type;
    let error;
    let oldRates;

    setupAjax();
    $.ajax({
      url: "/samples/discount",
      method: "GET",
      async: false,
      data: {
        code: this.discountCodeTarget.value,
        tier: this.tierTarget.innerText
      },
      success: function(data) {
        value = data.value
        type = data.type
        error = data.error
        oldRates = data.old_rates
      }
    })

    this.discountValueTarget.innerText = value
    this.discountErrorTarget.innerText = error
    this.discountTypeTarget.innerText = type

    if (oldRates == true) {
      this.oldRatesTarget.classList.remove('d-none')
    }

    if (type == 'fixed') {
      this.discountDollarSymbolTarget.classList.remove('d-none')
      this.discountPercentSymbolTarget.classList.add('d-none')
    } else {
      this.discountDollarSymbolTarget.classList.add('d-none')
      this.discountPercentSymbolTarget.classList.remove('d-none')
    }

    this.updatePricing()
  }

  removeDiscount() {
    this.discountCodeTarget.value = ''
    this.discountValueTarget.innerText = 0
    this.discountErrorTarget.innerText = ''
    this.discountTypeTarget.innerText = 'N/A'
    this.discountPercentSymbolTarget.classList.add('d-none')
    this.discountDollarSymbolTarget.classList.add('d-none')
    this.oldRatesTarget.classList.add('d-none')

    this.updatePricing()
  }

  updatePricing() {
    setupAjax();
    $.ajax({
      url: "/job_posting/cost_calculator/calculate/",
      method: "POST",
      data: {
        'posting_div': {
          "posting_type": "sample_posting", "tier": this.tierTarget.innerText
        },
        'discount_value': this.discountValueTarget.innerText,
        'discount_type': this.discountTypeTarget.innerText,
        'old_rates': this.oldRatesTarget.classList.contains('d-none') ? false : true
      }
    })
  }
}
