import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ 
    "chart", 
    "startDate", 
    "endDate", 
    "byType",
    "byOrderType",
    "ofTeam",
    "orderOverview",
    "ordersByPackage"
  ]

  connect() {
    console.log("Allocations connected");
    
    this.chart = new ApexCharts(this.chartTarget, this.getOptions());
    this.chart.render();

    this.renderAllocations();
  }

  /**
   * Gets the allocations data from server and is displayed to view
   *
   */
  renderAllocations() {
    var that = this;

    setupAjax();
    $.ajax({
      url: "/admin/allocations",
      method: "POST",
      data: {
        allocation: {
          start_date: that.startDateTarget.value,
          end_date: that.endDateTarget.value
        }
      },
      dataType: "json",
      success: function (data) {
        that.updateChart(data.chart_data);
        that.updateStats(data);
      }
    });
  }

  /**
   * Updates the rendered chart with the data points passed in
   *
   * @param {hash} hash the hashed data to be displayed in the chart
   */
  updateChart(hash) {
    var customerAllocations = [];
    var managementAllocations = [];
    var newOrders = [];
    var dates = [];

    $.each(hash, function(k, v) {
      dates.push(k);
      customerAllocations.push(v[0]);
      managementAllocations.push(v[1]);
      newOrders.push(v[2]);
    });
  
    this.chart.updateOptions(this.getOptions(customerAllocations, managementAllocations, newOrders, dates));
  }

  /**
   * Updates the rendered stats in the view with latest passed in
   *
   * @param {hash} stats the hashed data to be displayed in view
   */
  updateStats(stats) {
    this.updateOFTeamStats(stats.management_allocations);
    this.updateOrderTypeAllocationStats(stats.order_type_allocations);
    this.updateAllocationTypesStats(stats.allocations);

    this.updateOrderStats(stats.order_stats);
    this.updateWritingOrdersStats(stats.writing_orders_stats);
  }

  /**
   * Updates the Order Fulfillment team stats for the view
   *
   * @param {hash} allocations the allocation data to be displayed
   */
  updateOFTeamStats(allocations) {
    $(this.ofTeamTarget).empty();

    var that = this;
    $.each(allocations, function(k, v) {
      var numAllocations = v[0];
      var imgUrl = v[1];
      var name = k;
      $(that.ofTeamTarget).append(that.statRender(imgUrl, name, numAllocations));
    });
  }

  /**
   * Updates the Order Type Allocation stats for the view
   *
   * @param {hash} allocations (optional) the allocation data to be displayed
   */
  updateOrderTypeAllocationStats(allocations) {
    $(this.byOrderTypeTarget).empty();

    var that = this;
    $.each(allocations, function(k, v) {
      var type = k.replaceAll("_", " ");
      var numAllocations = v[0];
      var symbolCSS = v[1];
      $(that.byOrderTypeTarget).append(that.statRender(null, type, numAllocations, symbolCSS))
    });
  }

  /**
   * Updates the Allocations by type stats for the view
   *
   * @param {hash} allocations the allocation data to be displayed
   */
  updateAllocationTypesStats(allocations) {
    $(this.byTypeTarget).empty();

    var that = this;
    $.each(allocations, function(k, v) {
      var type = k.replaceAll("_", " ");
      var numAllocations = v[0];
      var symbolCSS = v[1];
      $(that.byTypeTarget).append(that.statRender(null, type, numAllocations, symbolCSS))
    });
  }

  /**
   * Updates the New Order Stats for the view
   *
   * @param {hash} stats the allocation data to be displayed
   */
   updateOrderStats(stats) {
    $(this.orderOverviewTarget).empty();

    var that = this;
    $.each(stats, function(k, v) {
      var type = k.replaceAll("_", " ");
      var numAllocations = v;
      $(that.orderOverviewTarget).append(that.statRender(null, type, numAllocations))
    });
  }

  /**
   * Updates the stats for writing orders and displays in view
   *
   * @param {hash} stats the allocation data to be displayed
   */
   updateWritingOrdersStats(stats) {
    $(this.ordersByPackageTarget).empty();

    var that = this;
    $.each(stats, function(k, v) {
      var type = k.replaceAll("_", " ");
      var numAllocations = v;
      $(that.ordersByPackageTarget).append(that.statRender(null, type, numAllocations))
    });
  }

  /**
   * Gets the options hash for the ApexChart
   *
   * @param {hash} allocations (optional) the data points for the allocations series
   * @param {hash} dates (optional) the data points for the dates, xaxis
   */
   getOptions(customerAllocations = [], managementAllocations = [], orders = [], dates = []) {
    var options = {
      chart: {
        type: 'bar',
        height: '300px'
      },
      series: [{
        name: 'Customer Allocated',
        data: customerAllocations
      },
      {
        name: 'Management Allocated',
        data: managementAllocations
      },
      {
        name: 'New Orders',
        data: orders
      }],
      xaxis: {
        categories: dates
      }
    }

    return options;
  }

  /**
   * Gets the html to render the individual stat
   *
   * @param {hash} allocations (optional) the data points for the allocations series
   */
   statRender(imgUrl, name, numAllocations, symbolCSS = 'symbol-light') {
    var imgTag = '<img src="'+ imgUrl + '" class="h-50 align-self-center" alt="">';
    if (imgUrl == null) {
      imgTag = "";
    }

    var html =  '<div class="d-flex align-items-center flex-wrap mb-8">' +
                  '<div class="symbol symbol-50 ' + symbolCSS + ' mr-5">' +
                    '<span class="symbol-label">' +
                      imgTag +
                    '</span>' +
                  '</div>' +
                  '<div class="d-flex flex-column flex-grow-1 mr-2">' +
                    '<span class="font-weight-bold text-dark-75 text-hover-primary font-size-lg mb-1 text-capitalize">' + name + '</span>' +
                  '</div>' +
                  '<span class="label label-xl label-light label-inline my-lg-0 my-2 text-dark-50 font-weight-bolder">' + numAllocations + '</span>' +
                '</div>';
    
    return html;
  }

}
