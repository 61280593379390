import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ['submitButton']

  /**
    * Disables submit button, so no more than 1 requests happens at once.
  */
  onSubmit() {
    this.submitButtonTarget.disabled = true
    this.submitButtonTarget.innerText = 'Requesting...'
  }
}
