import { Controller } from "stimulus"
import { formatDistanceToNow } from "date-fns";
import { format } from "date-fns";

const parsedTime = (time) => {
  let parsedTime = Date.parse(time)
  let options = {
    includeSeconds: false,
    addSuffix: true
  }
  return formatDistanceToNow(parsedTime, options)
};

const formattedTime = (time) => {
  let parsedTime = Date.parse(time)

  return format(parsedTime, 'MMM d, yyyy h:mm a')
};

export default class extends Controller {
  static targets = [ "messages", "messageForm", "blankOutgoing", "blankIncoming" ]

  initialize() {
    // Use javascripts/chat.js from theme to scroll messages inside window
    window.KTAppChat.init();
  }

  connect() {
    console.log("components/chat_panel connected");

    const convo_id = this.element.getAttribute("data-conversation");

    this.textAreaHeight = 38;

    this.chatBoxSpinner = $("#chat-box-spinner");
    this.chatBoxSpinner.html(spinner());

    // array of messages arranged newest first
    this.conversationMessages = [];

    this.clientActions(convo_id);
  }

  clientActions(convo_sid) {
    let that = this;

    setupAjax();
    $.ajax({
      url: "/conversations/messages",
      method: "GET",
      dataType: "json",
      data: {
        conversation: {
            sid: convo_sid
        },
      },
      success: function (data) {
        that.chatBoxSpinner.empty();
        data.messages.forEach(message => {
          that.insertMessage(message);
        })
        let conversationPanel = document.getElementById(`chat-panel-message-container-${convo_sid}`);
        let messages = document.getElementById(`messages-${convo_sid}`);

        if (conversationPanel && messages) {
          conversationPanel.scrollTop = messages.scrollHeight;
        }
      }
    });
  }

  insertMessage(message) {
    let currentAuthor = this.element.getAttribute("data-current-author");
    let authorName = this.element.getAttribute("data-author-name");
    let messageTemplate;

    if (message.user_id == currentAuthor || (message.sender_name === 'Admin' && authorName === 'Admin')) {
      // outgoing message
      messageTemplate = this.blankOutgoingTarget.innerHTML;
    } else {
      // incoming message
      messageTemplate = this.blankIncomingTarget.innerHTML;
    }

    let avatar = document.getElementById(`avatar_${message.author}`);
    if (!!avatar) {
      let regex = /src="([^"]*)"/g;
      messageTemplate = messageTemplate.replace(regex, `src="${avatar.src}"`);
    }

    if (message.sender_name == 'System Message') {
      messageTemplate = messageTemplate.replace("%MESSAGE_BODY%", `<span class='system-message-color'>${message.body}</span>`);
    } else {
      messageTemplate = messageTemplate.replace("%MESSAGE_BODY%", message.body);
    }

    messageTemplate = messageTemplate.replace('%MESSAGE_TIME%', parsedTime(message.created_at));
    messageTemplate = messageTemplate.replace('%FORMATTED_TIME%', formattedTime(message.created_at));
    messageTemplate = messageTemplate.replace('%MESSAGE_INDEX%', message.index);
    messageTemplate = messageTemplate.replace('%SENDER_NAME%', message.sender_name);
    messageTemplate = messageTemplate.replace('%SENDER_LINK%', message.sender_link);

    this.messagesTarget.insertAdjacentHTML('beforeend', messageTemplate);
    this.conversationMessages.unshift(message);
  }
}
