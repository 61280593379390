import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["popup"];

  renderInternalRatings(event) {
    this.popupTarget.classList.remove("d-none");
    $("#search_popup").append(spinner());

    setupAjax();
    $.ajax({
      url: "/order_ratings/average_order_rating",
      method: "GET",
      dataType: "json",
      data: {
        order_rating: {
          id: event.currentTarget.dataset.ratingid,
          average: event.currentTarget.dataset.averagerating,
          employee_id: event.currentTarget.dataset.employeeid
        },
      },
      success: function (data) {
        $("#search_popup").html("<small>Internal Review</small> <dl id='internalRatings'></dl>");
        if(data.is_writer) {
          showWriterRatings(data)
        } else {
          showEditorRatings(data)
        }
      },
    });
  }

  renderCustomerEmployeeRatings(event) {
    this.popupTarget.classList.remove("d-none");
    $("#search_popup").append(spinner());

    setupAjax();
    $.ajax({
      url: "/customer_employee_ratings/employee_ratings",
      method: "GET",
      dataType: "json",
      data: {
        customer_employee_rating: {
          id: event.currentTarget.dataset.ratingid,
          average: event.currentTarget.dataset.averagerating,
          rated_employee_id: event.currentTarget.dataset.employeeid
        },
      },
      success: function (data) {
        $("#search_popup").html(
          "<small>Customer Review</small>" +
          "<dl>" +
            "<dt>Quality of Work</dt>" +
            "<dd>" +
            getStars(data.quality_work, false) +
            "</dd>" +
            "<dt>Communication</dt>" +
            "<dd>" +
            getStars(data.communication, false) +
            "</dd>" +
            "<dt>Adherence to Schedule</dt>" +
            "<dd>" +
            getStars(data.adherence_schedule, false) +
            "</dd>" +
            "<dt>Cooperation</dt>" +
            "<dd>" +
            getStars(data.cooperation, false) +
            "</dd>" +
            "</dl>"
        );
      },
    });
  }

  renderEmployeeCustomerRatings(event) {
    this.popupTarget.classList.remove("d-none");
    $("#search_popup").append(spinner());

    setupAjax();
    $.ajax({
      url: "/employee_customer_ratings/customer_ratings",
      method: "GET",
      dataType: "json",
      data: {
        employee_customer_rating: {
          id: event.currentTarget.dataset.ratingid,
          average: event.currentTarget.dataset.averagerating,
          customer_id: event.currentTarget.dataset.customerid
        },
      },
      success: function (data) {
        $("#search_popup").html(
          "<dl>" +
            "<dt>Requirements Detail</dt>" +
            "<dd>" +
            getStars(data.detail, false) +
            "</dd>" +
            "<dt>Professionalism</dt>" +
            "<dd>" +
            getStars(data.professionalism, false) +
            "</dd>" +
            "<dt>Recommendation Likelihood</dt>" +
            "<dd>" +
            getStars(data.recommendation, false) +
            "</dd>" +
            "<dt>Responsiveness</dt>" +
            "<dd>" +
            getStars(data.responsiveness, false) +
            "</dd>" +
            "</dl>"
        );
      },
    });
  }

  // Positions pop up below cursor
  updatePopup(event) {
    $("#search_popup").css({ top: event.pageY + 20, left: event.pageX - 10 });
  }

  // Removes display hidden class
  showRatings(event) {
    event.currentTarget.children[
      event.currentTarget.childElementCount - 1
    ].classList.remove("d-none");
  }

  // Adds display hidden class and makes html empty
  removeRatings(event) {
    $("#search_popup").html("");
    if (this.hasPopupTarget) {
      this.popupTarget.classList.add("d-none");
    } else {
      event.currentTarget.children[
        event.currentTarget.childElementCount - 1
      ].classList.add("d-none");
    }
  }

  modifyReviewText(id, visibleCount) {
    document.getElementById(id).textContent = `${visibleCount > 0 ? 1 : 0} to ${visibleCount}`;
  }

  filterNiche(event) {
    let filter = event.target.value;

    let visibleReviewCount = 0;

    $('*[class*=employeeReview]').each(function() {
      if (this.querySelector(".reviewNiche") && this.querySelector(".reviewNiche").textContent == filter || filter == '') {
        this.style.display = '';
        visibleReviewCount++;
      } else {
        this.style.display = 'none';
      }
    });

    this.modifyReviewText('review-count', visibleReviewCount);

    let visibleInternalReviewCount = 0;

    $('*[class*=employeeInternalReview]').each(function() {
      if (this.querySelector(".reviewNiche") && this.querySelector(".reviewNiche").textContent == filter || filter == '') {
        this.style.display = '';
        visibleInternalReviewCount++;
      } else {
        this.style.display = 'none';
      }
    });

    this.modifyReviewText('internal-review-count', visibleInternalReviewCount);
  }
}

// Creates star rating based of rating value
function getStars(rating, greenStar) {
  if (typeof Number(rating) !== 'number') {
    return "<h5>N/A</h5>";
  }

  var output = `<h5 class='d-inline'>${Number(rating).toFixed(1) }</h5>  `;
  var floored = Math.floor(rating);
  var imagePath = greenStar ? '-green' : ''

  for (var i = 0; i < floored; i++) {
    output += `<img class='mb-2' src='/star-full${imagePath}.png'/>`;
  }
  if (rating - floored >= 0.5) {
    output += `<img class='mb-2' src='/star-half${imagePath}.png'/>`;
  }
  if (rating < 4.5) {
    var empty_stars_amount = (rating.toString().includes('.5')) ? Math.floor(5 - rating) : Math.round(5 - rating)
    for (var i = 0; i < empty_stars_amount; i++) {
      output += "<img class='mb-2' src='/star-empty.png'/>";
    }
  }
  return output;
}

function showWriterRatings(data) {
  if(data.writing > 0){
    $("#internalRatings").append(
      "<dt>Writing</dt>" +
      "<dd>" +
      getStars(data.writing, true) +
      "</dd>"
    );
  }
  if(data.communication_rating > 0){
    $("#internalRatings").append(
      "<dt>Communication</dt>" +
      "<dd>" +
      getStars(data.communication_rating, true) +
      "</dd>"
    );
  }
  if(data.content > 0){
    $("#internalRatings").append(
      "<dt>Content</dt>" +
      "<dd>" +
      getStars(data.content, true) +
      "</dd>"
    );
  }
}

function showEditorRatings(data) {
  if(data.editing_style > 0){
    $("#internalRatings").append(
      "<dt>Editing Style</dt>" +
      "<dd>" +
      getStars(data.editing_style, true) +
      "</dd>"
    );
  }
  if(data.editor_communication > 0){
    $("#internalRatings").append(
      "<dt>Communication</dt>" +
      "<dd>" +
      getStars(data.editor_communication, true) +
      "</dd>"
    );
  }
  if(data.editor_professionalism > 0){
    $("#internalRatings").append(
      "<dt>Professionalism</dt>" +
      "<dd>" +
      getStars(data.editor_professionalism, true) +
      "</dd>"
    );
  }
}
