import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "historyView", "orderId" ];

  load() {
    var that = this;
    var orderId = this.orderIdTarget.dataset.id;
    setupAjax();
    $.ajax({
      url: "/orders/comments",
      method: "POST",
      data: {
        order: {
          id: orderId
        }
      },
      dataType: "json",
      success: function (data) {
        var html = "";
        var comments = JSON.parse(data.comments);
        comments.forEach(function(comment) {
          if (comment.approved) {
            html += that.displayComment(comment);
          }
        });

        $(that.historyViewTarget).empty();
        $(that.historyViewTarget).append(html);
      }
    });
  }

  displayComment(comment) {
    var html =  '<div>' +
                  '<div class="row mt-4">' +
                    '<div class="col-md-12">' +
                      'Comment: ' +
                      '<small class="text-muted">' + comment.posted_on + '</small>' +
                    '</div>' +
                  '</div>' +
                  '<div class="row mt-4">' +
                    '<div class="col-md-1">' +
                      '<p>' + comment.line_num + '</p>' +
                    '</div>' +
                    '<div class="col-md-9">' +
                      '<pre class="text-danger">' + comment.line_text + '</pre>' +
                    '</div>' +
                  '</div>' +
                  '<div class="row mt-1">' +
                    '<div class="offset-md-1 col-md-9">' +
                      '<pre class="text-black">' + comment.comment + '</pre>' +
                    '</div>' +
                  '</div>' +
                  '<div class="row mt-1">' +
                    '<div class="offset-md-1 col-md-9">' +
                      '<pre class="text-success">' + comment.updated_text + '</pre>' +
                    '</div>' +
                  '</div>' +
                  '<hr>' +
                '</div>';
    
    return html
  }
}
