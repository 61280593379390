import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["output"];
  static values = { url: String };

  connect() {
    this.request = new Request(this.urlValue);
    this.fetchContent(this.request);
  }

  fetchContent(request) {
    fetch(request)
      .then((response) => {
        if (response.status === 200) {
          response.text().then((text) => this.renderContent(text));
        } else {
          this.renderContent("<p class='text-center p-6'>Could not load data</p>");
        }
      })
      .catch((error) => {
        this.renderContent("<p class='text-center p-6'>Could not load data</p>");
      });
  }

  renderContent(content) {
    this.outputTarget.innerHTML = content;
    this.dispatchEvent("lazy_load:complete");
    $(function () { $('[data-toggle="tooltip"]').tooltip() })
  }

  dispatchEvent(eventName) {
    const event = new Event(eventName);
    document.dispatchEvent(event);
  }
}
