import { Controller } from "stimulus"

export default class extends Controller {
  delete(event) {
    let row = event.currentTarget.parentNode.parentNode;
    setupAjax();
    $.ajax({
      url: "/reliability_additions/" + row.dataset.id,
      method: "DELETE",
      success: function (data) {
        row.remove();
        document.getElementsByClassName('apexcharts-datalabel-value')[0].innerHTML = data.reliability_score + "%"
      }
    });
  }
}