import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "startDate", "style", "days", 'type' ];

  initialize() {
    this.countdownTypes = ['approval', 'resume', 'on_hold'];
    this.countdownTitles = ['Approval', 'Resume', 'On Hold'];
  }

  connect() {
    console.log("Countdown connected");
    this.startTimer();
  }

  startTimer() {
    var startDateTime = new Date(this.startDateTarget.dataset.date.replace(/ /g,"T")).getTime();
    this.setTimer(startDateTime);
  }

  setTimer(startDateTime) {
    // Update the count down every 1 second
    var revDays = this.daysTarget.dataset.days;
    var revMilliseconds = this.milliseconds(revDays);
    var styleTarget = this.styleTarget;
    let typeTarget = this.typeTarget.dataset.type;
    var displayed = false;
    let that = this;

    var x = setInterval(function() {

      // Get revision period date
      var revisionPeriodTime = new Date(startDateTime + revMilliseconds).getTime();

      // Find the distance between now and the count down date
      var today = new Date().getTime();
      var distance = revisionPeriodTime - today;

      // Time calculations for days, hours, minutes and seconds
      var days = Math.floor(distance / (1000 * 60 * 60 * 24));
      var hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
      var minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
      var seconds = Math.floor((distance % (1000 * 60)) / 1000);

      // in cases when the order was moved manually and editor fulfilled time is missing
      if (isNaN(startDateTime)) {
        document.getElementById("timer").innerHTML = that.buildCountdownMessage(typeTarget, null, 'unavailable');
      } else {
        // Display the result in the element with id="demo"
        document.getElementById("timer").innerHTML = that.buildCountdownMessage(typeTarget,
          {'d': days, 'h': hours, 'm': minutes, 's': seconds}, 'available');
      }

      if (days < 3 && !displayed) {
        styleTarget.classList.add("alert-danger");
      } else if (!displayed) {
        styleTarget.classList.add("alert-success");
      }

      // If the count down is finished, write some text
      if (distance < 0) {
        clearInterval(x);
        document.getElementById("timer").innerHTML = that.buildCountdownMessage(typeTarget, null, 'expired');
      }

      if (!displayed) {
        displayed = true;
        styleTarget.classList.remove("d-none");
      }
    }, 1000);
  }

  // Returns the number of milliseconds in a day
  milliseconds(days) {
    return days * 24 * 60 * 60 * 1000;
  }

  buildCountdownMessage(typeTarget, values, status) {
    // let countdownType = typeTarget === this.countdownTypes[1] ? 'Resume' : 'Approval';
    let countdownType = this.countdownTitles[this.countdownTypes.indexOf(typeTarget)];

    if (status == 'unavailable') {
      return countdownType + ' Time is Unavailable. Please email <strong>support@dibbly.com.</strong>';
    } else if (status == 'available') {
      return countdownType + ' Time Left - <strong>' + values['d'] + "d "
      + values['h'] + "h " + values['m'] + "m " + values['s'] + "s</strong>";
    } else {
      return countdownType + ' Time Left - <strong>Expired, you have passed your ' + countdownType + ' time period.</strong>';
    }
  }
}
