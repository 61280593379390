import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ 
    "startDate",
    "endDate",
    "chart",
    "selectManager",
    "activeManagedOrders",
    "supportedOrders",
    "guidedOrders",
    "turnkeyOrders",
    "manAvgProjTurnAroundTime",
    "manAvgChatResponseTime",
    "manAvgCustomerRating",
    "avgProjTurnAroundTime",
    "avgChatResponseTime",
    "avgCustomerRating",
  ]
  
  connect() {
    // this.chart = new ApexCharts(this.chartTarget, this.getOptions());
    // this.chart.render();
    this.loadData();
  }

  /**
   * Loads the Interactions data to be displayed
   *
   */
  loadData() {
    let manager_id = this.selectManagerTarget.value;
    let startDate = this.startDateTarget.value;
    let endDate = this.endDateTarget.value;

    let that = this;
    setupAjax();
    $.ajax({
      url: "/analytics/managed_services_stats/stats",
      method: "POST",
      data: {
        managed_services_stats: {
          manager_id: manager_id,
          start_date: startDate,
          end_date: endDate
        }
      },
      dataType: "json",
      success: function (data) {
       // that.updateChart(data.chart_data);
       that.updateStats(data);
      }
    });
  }

  loadManagerData() {
    let that = this;
    let manager_id = that.selectManagerTarget.value;

    setupAjax();
    $.ajax({
      url: "/analytics/managed_services_stats/manager_stats",
      method: "POST",
      data: {
        managed_services_stats: { manager_id: manager_id }
      },
      dataType: "json",
      success: function (data) {
        that.updateManagerStats(data);
      }
    });
  }

  /**
   * Updates the stats data to be displayed for the user
   *
   * @param {Hash} data containing the managed services analytics data to be displayed
   */
  updateStats(data) {
    this.activeManagedOrdersTarget.innerHTML = this.getStatHTMLRender(data['stats'][0]);
    this.supportedOrdersTarget.innerHTML = this.getStatHTMLRender(data['stats'][1]);
    this.guidedOrdersTarget.innerHTML = this.getStatHTMLRender(data['stats'][2]);
    this.turnkeyOrdersTarget.innerHTML = this.getStatHTMLRender(data['stats'][3]);

    this.updateManagerStats(data);

    this.avgProjTurnAroundTimeTarget.innerHTML = this.getStatHTMLRender(data['overall_stats'][0]);
    this.avgChatResponseTimeTarget.innerHTML = this.getStatHTMLRender(data['overall_stats'][1]);
    this.avgCustomerRatingTarget.innerHTML = this.getStatHTMLRender(data['overall_stats'][2]);
  }

  /**
   * Updates the manager stat only
   *
   * @param {Hash} data containing the stats of a particular manager
   *
   * @return noting
  **/
  updateManagerStats(data) {
    this.manAvgProjTurnAroundTimeTarget.innerHTML = this.getStatHTMLRender(data['manager_stats'][0]);
    this.manAvgChatResponseTimeTarget.innerHTML = this.getStatHTMLRender(data['manager_stats'][1]);
    this.manAvgCustomerRatingTarget.innerHTML = this.getStatHTMLRender(data['manager_stats'][2]);
  }

  /**
   * Updates the chart data to be displayed for the user
   *
   * @param {hash} chartData containing the customer interaction data to be displayed
   *    {date: [customerSignups, customerAffiliateSignups, jobPostings]}
   */
  updateChart(chartData) {
    let customerSignups = [];
    let customerAffiliateSignups = [];
    let jobPostings = [];
    let dates = [];

    // Get chart data and populate the relevant arrays to prepare
    // for display
    $.each(chartData, function(key, value) {
      dates.push(key);
      customerSignups.push(value[0]);
      customerAffiliateSignups.push(value[1]);
      jobPostings.push(value[2]);
    });

    this.chart.updateOptions(
      this.getOptions(
        customerSignups,
        jobPostings,
        customerAffiliateSignups, 
        dates
      )
    );
  }

  /**
   * Gets the options hash for the ApexChart
   *
   * @param {hash} customerSignups (optional) the data points for customer signups
   * @param {hash} jobPostings (optional) the data points for the number of job postings
   * @param {hash} affiliateCustomerSignups (optional) the data points for affiliate customer signups
   * @param {hash} dates (optional) the data points for the dates, xaxis
   */
  getOptions(customerSignups = [], jobPostings = [], affiliateCustomerSignups = [], dates = []) {
    let options = {
      chart: {
        type: 'bar',
        height: '300px'
      },
      series: [{
        name: 'Customer Signups',
        data: customerSignups
      },
      {
        name: 'Job Postings',
        data: jobPostings
      },
      {
        name: 'Affiliate Customer Signups',
        data: affiliateCustomerSignups
      }],
      xaxis: {
        categories: dates
      }
    }

    return options;
  }

  /**
   * Gets the html render for the stat, to be displayed to user
   *
   * @param {integer} stat the stat for rendering
   */
  getStatHTMLRender(stat) {
    return '<span class="label label-xl label-light label-inline my-lg-0 my-2 text-dark-50 font-weight-bolder">' + stat + '</span>';
  }
}
