import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ['dollarsField', 'pointsField', 'dollarsText', 'pointsText']

  connect() {
    this.dollarsChanged()
    this.pointsChanged()
  }

  dollarsChanged(e) {
    if (this.hasDollarsFieldTarget) {
      this.sendRequest(this.dollarsFieldTarget.value, "dollars")
    }
  }

  pointsChanged(e) {
    if (this.hasPointsFieldTarget) {
      this.sendRequest(this.pointsFieldTarget.value, "points")
    }
  }

  sendRequest(value, type) {
    setupAjax()
    $.ajax({
      url: "/reward_points/calculate",
      method: "GET",
      dataType: "json",
      data: {
        value: value,
        type: type
      },
      success: (data) => {
        if (type == "dollars") {
          this.pointsTextTarget.innerText = data.value
        } else {
          this.dollarsTextTarget.innerText = data.value
        }
      }
    })
  }
}
