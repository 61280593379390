import { Controller } from "stimulus"

export default class extends Controller {

  changeStatus(event) {
    if (event.target.value == 6) {
      $("#change_reason").show();
    } else {
      $("#change_reason").hide();
      $("#employee_note_note").removeAttr('required');
    }
  }

  changeReason(event) {
    if (event.target.value == 'Other') {
      $("#employee_note_note").attr('required', '');
      $("#employee_note_note").removeClass('d-none');

    } else {
      $("#employee_note_note").removeAttr('required');
      $("#employee_note_note").addClass('d-none');
    }
  }
    
}
