import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['postingField', 'submitButton']

  postingChange(event) {
    let url = `/job_posting/cost_calculator/fields/?posting_type=${event.target.value}&id=${event.target.dataset.postingId}`

    setupAjax();
    $.ajax({ url: url, method: "GET" })
  }

  updateNames() {
    this.postingFieldTargets.forEach((e, i) => {
      var id = e.id

      document.getElementById(`${id}_cost_name`).innerHTML = i + 1
      document.getElementById(`${id}_name`).innerHTML = i + 1
    })
  }

  updatePricing() {
    this.submitButtonTarget.click()
  }
}
