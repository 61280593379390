import { Controller } from "stimulus"

const stripCamelize = (str) => {
  return str.replace(/(?:^\w|[A-Z]|\b\w)/g, function(word, index) {
    return index === 1 ? word.toLowerCase() : word.toUpperCase();
  }).replace(/\s+/g, '').replace(/&/g, '');
}

export default class extends Controller {

  // targets are the headers to highlight,
  // and ...Content are content blocks
  static targets = [
    "draftApprovals",
    "plagiarismReportApprovals",
    "designIllustrationApprovals",
    "editingApprovals",
    "extensionRequests",
    "draftApprovalsContent",
    "plagiarismReportApprovalsContent",
    "designIllustrationApprovalsContent",
    "editingApprovalsContent",
    "extensionRequestsContent"
  ];

  updateSymbol(event) {
    let renderedElement = event.currentTarget.firstChild;

    if (!!renderedElement && renderedElement.dataset.count > 0) {
      document.getElementById(renderedElement.dataset.symbolId).classList.remove('d-none')
    }
  }

  // Activate the clicked menu and show its associated content block
  activateClickedTarget(event) {
    event.preventDefault();
    let clickedTarget = event.currentTarget;
    let selectedClass = "notif_active";

    this.clearHeaderTargets(selectedClass);
    this.hideContentTargets("hidden");
    this.setActiveTarget(clickedTarget);

    clickedTarget.classList.add(selectedClass);

    let contentTarget = stripCamelize(clickedTarget.innerText) + "ContentTarget";
    this[contentTarget].classList.remove('hidden');
  }

  setActiveTarget(clickedTarget) {
    let activeClass = 'profile-component-link-active';

    this.draftApprovalsTarget.classList.remove(activeClass);
    this.plagiarismReportApprovalsTarget.classList.remove(activeClass);
    this.designIllustrationApprovalsTarget.classList.remove(activeClass);
    this.editingApprovalsTarget.classList.remove(activeClass);
    this.extensionRequestsTarget.classList.remove(activeClass);

    clickedTarget.classList.add(activeClass);
  }

  // clear all target selections
  clearHeaderTargets(selectedClass) {
    this.draftApprovalsTarget.classList.remove(selectedClass);
    this.plagiarismReportApprovalsTarget.classList.remove(selectedClass);
    this.designIllustrationApprovalsTarget.classList.remove(selectedClass);
    this.editingApprovalsTarget.classList.remove(selectedClass);
    this.extensionRequestsTarget.classList.remove(selectedClass);
  }

  hideContentTargets(selectedClass) {
    this.draftApprovalsContentTarget.classList.add(selectedClass);
    this.plagiarismReportApprovalsContentTarget.classList.add(selectedClass);
    this.designIllustrationApprovalsContentTarget.classList.add(selectedClass);
    this.editingApprovalsContentTarget.classList.add(selectedClass);
    this.extensionRequestsContentTarget.classList.add(selectedClass);
  }
}
