import { Controller } from "stimulus";

export default class extends Controller {
  static targets = [
    "startDate",
    "endDate",
    "package",
    "newPaidOrders",
    "newJpOrders",
    "withRequests",
    "withOutRequests",
    "riskIndicator",
    "isFiction"
  ];

  connect() {
    this.loadData();
  }

  /**
   * Loads order data to be displayed
   */
  loadData() {
    let startDate = this.startDateTarget.value;
    let endDate = this.endDateTarget.value;
    let orderPackage = this.packageTarget.value;
    let isFiction = this.isFictionTarget.value;

    var that = this;
    setupAjax();
    $.ajax({
      url: "/analytics/order_overview/stats",
      method: "POST",
      data: {
        search: {
          start_date: startDate,
          end_date: endDate,
          package: orderPackage,
          is_fiction: isFiction
        },
      },
      dataType: "json",
      success: function (data) {
        that.updateStats(data.stats);
      },
    });
  }

  /**
   * Updates the stats data to be displayed for the user
   *
   * @param {hash} stats contains order statstic date
   */
  updateStats(stats) {
    this.newPaidOrdersTarget.innerHTML = this.getStatHTMLRender(stats[0]);
    this.newJpOrdersTarget.innerHTML = this.getStatHTMLRender(stats[1]);
    this.withRequestsTarget.innerHTML = this.getStatHTMLRender(stats[2]);
    this.withOutRequestsTarget.innerHTML = this.getStatHTMLRender(stats[3]);
    this.riskIndicatorTarget.innerHTML = this.getIndicatorRender(stats[4]);
  }

  /**
   * Gets the html render for the stat
   *
   * @param {integer} stat the stat for rendering
   */
  getStatHTMLRender(stat) {
    return (
      '<span class="label label-xl label-light label-inline my-lg-0 my-2 text-dark-50 font-weight-bolder">' +
      stat +
      "</span>"
    );
  }

  /**
   * Gets the html render for the risk indicator
   *
   * @param {float} stat the percentage to determine which render to use
   */
  getIndicatorRender(stat) {
    if (stat >= 0.5) {
      return '<i class="fas fa-circle fa-10x high-risk"></i>';
    } else if (stat >= 0.3) {
      return '<i class="fas fa-circle fa-10x medium-risk"></i>';
    } else if (stat >= 0.1) {
      return '<i class="fas fa-circle fa-10x low-risk"></i>';
    } else {
      return '<i class="fas fa-circle fa-10x no-risk"></i>';
    }
  }
}
