import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ['tier', 'fiction', 'nonFiction', 'niches', 'fictionCheckBox', 'writingStyle']
  pageJustLoaded = true;

  connect() {
    this.tierChanged()
    this.fictionChanged()

    this.pageJustLoaded = false;
  }

  fictionChanged() {
    this.tierTarget.options.forEach(e => {
      if (this.fictionCheckBoxTarget.checked && e.value == 'Finance and Technology') {
        e.disabled = true
      } else {
        e.disabled = false
      }
    })

    if (this.fictionCheckBoxTarget.checked) {
      this.nonFictionTarget.style.display = 'none'
      this.fictionTarget.style.display = 'block'
    } else {
      this.nonFictionTarget.style.display = 'block'
      this.fictionTarget.style.display = 'none'
      this.writingStyleTarget.selectedIndex = 0
    }

    if (this.pageJustLoaded) return;

    let changed = false
    this.tierTarget.options.forEach(e => {
      if (!e.disabled && !changed) {
        e.selected = true
        changed = true
      }
    })

    this.changeNiches()
  }

  tierChanged() {
    this.changeNiches()
  }

  changeNiches() {
    let key = this.tierTarget.value
    let data = JSON.parse(this.nonFictionTarget.dataset.niches)
    let list;

    if (data.hasOwnProperty(key)) {
      list = data[key]
    } else {
      list = data['Other']
    }

    this.nichesTarget.options.forEach(e => {
      if (list.includes(e.value) || e == this.nichesTarget.options[0]) {
        e.disabled = false
      } else {
        e.disabled = true
      }
    })

    this.nichesTarget.selectedIndex = 0
  }
}
