import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = [ "panel" ]

  connect() {
    console.log('conversation-list component connected')
    this.checkTwilio()

    this.selectedChatUnique = "";
  }

  checkTwilio() {
    let controller = this;

    let loader = setInterval(function() {
      if (window.twilioClient) {
        controller.clientActions(window.twilioClient);
        clearInterval(loader);
      }
    }, 1000);
  }

  clientActions(client) {
    let controller = this;
    this.twilioClient = client;

    client.getSubscribedConversations().then(response => {
      response.items.forEach(conversation => {
        this.displayMessageCount(conversation);

        conversation.on("messageAdded", (_message) => {
          // Do not add to unread count if user is viewing a conversation
          if (conversation.uniqueName === controller.selectedChatUnique) {
            return;
          }
          controller.displayMessageCount(conversation);
        });

        conversation.on("participantUpdated", (_participant) => {
          // recalculate unread when a participant's last-read-index changes
          controller.displayMessageCount(conversation);
        })
      });
    });
  }

  selectChat(event) {
    event.preventDefault();
    let elementId = event.currentTarget.id.match(/chat-select-(?<id>.*)/).groups.id;
    this.selectedChatUnique = event.currentTarget.href.match(/#(.*)/)[1];

    this.element.dispatchEvent(new CustomEvent("selectChat", {
      bubbles: true,
      detail: { id: elementId},
    }));

    this.activateCurrentLink(event);

    let convo_name = event.currentTarget.children[0].id
    convo_name = convo_name.substring(convo_name.indexOf('_') + 1);
    let convo_element = $(`#conversation-message-count-${convo_name}`);
    if (convo_element !== undefined) {
      convo_element.innerText = "";
    }
  }

  displayMessageCount(conversation) {
    let displayObj = { unique: conversation.uniqueName }

    conversation.getUnreadMessagesCount().then(unreadCount => {
      displayObj["unreadCount"] = unreadCount;

      let el = $(`#conversation-message-count-${displayObj["unique"]}`);
      if (typeof jQuery === "function" && el instanceof jQuery) {
        el = el[0];
      }
      if (el === undefined) {
        console.log(`conversation ${displayObj["unique"]} not found`);
        return
      }

      if (unreadCount > 0) {
        el.innerText = `${displayObj["unreadCount"]} unread`
      } else {
        el.innerText = "";
      }
    });
  }

  activateCurrentLink(event) {
    let activeStyle = "bg-light-light";
    let activeLinks = $(`a.${activeStyle}`).toArray();

    activeLinks.forEach(element => {
      element.classList.remove(activeStyle);
    });
    event.currentTarget.classList.add(activeStyle);
  }
}
