import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ['processingFee', 'freelancersTotal', 'numberField', 'totalAmount']

  initialize() {
    this.baseProcessingFee = Number(this.numberFieldTarget.dataset.baseProcessingFee)
    this.processingPercentage = Number(this.numberFieldTarget.dataset.processingPercentage)

    this.updateTotals()
  }

  updateTotals() {
    var tipAmount = Number(this.numberFieldTarget.value)
    var totalAmount = tipAmount / (1 - (this.processingPercentage / 100))
    var processingFee = totalAmount - tipAmount

    this.processingFeeTarget.innerHTML = processingFee.toFixed(2)
    this.freelancersTotalTarget.innerHTML = tipAmount.toFixed(2)
    this.totalAmountTarget.innerHTML = totalAmount.toFixed(2)
  }
}
