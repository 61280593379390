import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ['multiplierField', 'multiplierText']

  multiplierChanged() {
    setupAjax()
    $.ajax({
      url: "/reward_points/calculate_multiplier",
      method: "GET",
      dataType: "json",
      data: {
        multiplier: this.multiplierFieldTarget.value,
      },
      success: (data) => {
        this.multiplierTextTarget.innerText = data.value
      }
    })
  }
}
