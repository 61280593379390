import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["selectedImage", "zoomedImage", "zoomModal"];

  displayPreview(event) {
    var modal = this.zoomModalTarget;

    //zooms in
    var modalImg = this.zoomedImageTarget;
    modal.style.display = "block";
    modalImg.src = event.target.src;
    $("body").css({"overflow":"hidden"});

    //zooms out
    modal.onclick = function () {
      $("body").css({"overflow":"auto"});
      modal.style.display = "none";
    };
  }
}
