import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ 'employeeName', 'denyContract', 'approveContract', 'form', 'statementOfWork' ];

  connect() {
    if (this.hasStatementOfWorkTarget) {
      this.generateStatementOfWork(event);
    }
  }

  displayContract(event) {
    var contractID = event.target.dataset.id;
    this.pullOrderPreviewData(contractID);
  }

  pullOrderPreviewData(contractID) {
    var that = this;

    setupAjax();
    $.ajax({
      url: '/contracts/get_contract_request',
      method: 'GET',
      data: {
        id: contractID
      },
      dataType: 'json',
      success: function (data) {
        if (data.order_badge) {
          that.showOrderData(data);
        } else {
          that.showFreelancerData(data);
        }
      }
    });
  }

  showOrderData(data) {
    this.denyContractTarget.value = data.id;
    this.approveContractTarget.value = data.id;
    this.employeeNameTarget.textContent = `${data.name} Contract For ${data.order_badge}`;
    let pdfDisplayingTag = `<iframe src=${data.contract} width="100%" height="100%" style="border: none;"> </iframe>`;
    $("#contract-preview").html(pdfDisplayingTag);
    $("#contract-download").attr("href", data.contract_download);
    $("#view-order").html("View Order");
    $("#view-order").attr("href", data.order_id);
  }

  showFreelancerData(data) {
    this.denyContractTarget.value = data.id;
    this.approveContractTarget.value = data.id;
    this.employeeNameTarget.textContent = `${data.name} Agreement`;
    let pdfDisplayingTag = `<iframe src=${data.contract} width="100%" height="100%" style="border: none;"> </iframe>`;
    $("#contract-preview").html(pdfDisplayingTag);
    $("#contract-download").attr("href", data.contract_download);
    $("#view-order").attr("href", data.order_id);
    $("#view-order").html("View Freelancer");

  }

  generate_freelancer_contract(event) {
    let url = `/contracts/freelancer_agreement?employee_id=${event.target.dataset.id}`

    setupAjax();
    $.ajax({ url: url, method: "GET" })
  }

  submitAgreement(event){
    event.preventDefault();

    let newUser = this.targets.find("freelancer").dataset.newuser == 'false';
    let validForm = event.currentTarget.form.checkValidity();

    if (validForm) {
      setupAjax();
      $.ajax({
        url: event.currentTarget.form.action,
        method: 'PUT',
        dataType: 'json',
        success: function (data) {
          $("#agreement-uploaded").removeClass("d-none");
          $("#agreement-upload-button").addClass("d-none");
          $("#agreement-failed").addClass("d-none");
          $('#freelancerAgreement').modal('toggle');

          if(newUser) {
            window.location.replace(window.location.origin);
          }
        },
        error: function (data) {
          $("#agreement-failed").removeClass("d-none");
        }
      });
    } else {
      alert("You must agree to the checkbox to submit");
    }
  }

  accountSetup(event) {
    if(!$('#uploaded').length && $("#agreement-uploaded").hasClass("d-none")){
      event.preventDefault();
      alert("Freelancer Agreement not uploaded");
    }
  }

  generateStatementOfWork(event) {
    let employee = this.targets.find("statementOfWork").dataset.id;
    let order = this.targets.find("statementOfWork").dataset.orderid;

    let url = `/employee/statement_of_work_agreements?employee_id=${employee}&order_id=${order}`

    setupAjax();
    $.ajax({ url: url, method: "POST" })
  }
}
