import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ 'approveEmployeeSlug', 'denyEmployeeSlug', 'employeeName' ];


  displayVideo(event) {
    var employeeSlug = event.target.dataset.id;
    this.pullOrderPreviewData(employeeSlug);
  }

  pullOrderPreviewData(slug) {
    var that = this;

    setupAjax();
    $.ajax({
      url: '/employees/get_profile_video',
      method: 'GET',
      data: {
        employee: {
          slug: slug
        }
      },
      dataType: 'json',
      success: function (data) {
        that.showOrderData(data);
      }
    });
  }

  showOrderData(data) {
    $('#profileVideo').attr('src', data.video);
    $("#profileVideo")[0].load();
    this.approveEmployeeSlugTarget.value = data.slug;
    this.denyEmployeeSlugTarget.value = data.slug;
    this.employeeNameTarget.textContent = data.name;
  }
}
