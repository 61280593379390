import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["option"]

  connect() {
    this.optionTargets.forEach((checkbox) => {
      checkbox.addEventListener('change', () => this.handleCheckboxChange(checkbox));
    });
  }

  handleCheckboxChange(selectedCheckbox) {
    if (selectedCheckbox.checked) {
      this.optionTargets.forEach((checkbox) => {
        if (checkbox !== selectedCheckbox) {
          checkbox.checked = false;
        }
      });
    }
  }
}
