import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['postingField', 'submitButton']

  connect() {
    let url = `/customer/dashboard/team_overview`

    setupAjax();
    $.ajax({ url: url, method: "GET" })
  }

  messageTeamMember(event) {
    $.ajax({
      type: "POST",
      url: "/conversations",
      data: {
        conversation: {
          direct_chatter_id: event.target.dataset.user,
        },
      },
      success: response => {
        let conversation_id = $(response).attr('id');
        window.open(`conversations?selected_channel=${conversation_id}&convo_id=true`, '_blank').focus();
      }
    });
  }
}
