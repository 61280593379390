import { Controller } from "stimulus"

export default class extends Controller {
  static targets= [ "spinner" ]

  connect() {
    console.log("Pinned Activity connected");
  }

  togglePin(event){
    var activityId = event.currentTarget.dataset.activityId
    var customerId = event.currentTarget.dataset.customerId
    var pinElement = event.currentTarget
    var unpinElement = document.getElementsByClassName('pinned')[0]
    var spinner = document.getElementById('general-spinner')

    setupAjax();
    $.ajax({
      url: "/pinned_activity/toggle_pin",
      method: "POST",
      data: {
        activity: {
          customer_id: customerId,
          activity_id: activityId
        }
      },
      success: function(data) {
        if (data.pin_status == "success") {
          spinner.style.display = 'block'
          pinElement.classList.remove("unpinned");
          pinElement.classList.add("pinned");
          pinElement.dataset.action = '';
          if (typeof unpinElement != 'undefined') {
            unpinElement.classList.remove("pinned");
            unpinElement.classList.add("unpinned");
          }
          location.reload();
        }
      }
    });
  }
}
