import { Controller } from "stimulus"

export default class extends Controller {

  static targets = [];

  connect() {
    console.log('active projects connected');
    let url = `/customer/dashboard/active_projects`;

    setupAjax();
    $.ajax({ url: url, method: "GET" })
  }


}
