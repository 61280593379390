import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ['message']

  connect() {
    const key = document.querySelector("meta[name='stripe-public-key']").content
    const stripe = Stripe(key)

    const clientSecret = this.messageTarget.dataset.clientSecret

    // Retrieve the PaymentIntent
    stripe.retrievePaymentIntent(clientSecret).then(({ paymentIntent }) => {
      switch (paymentIntent.status) {
        case 'succeeded':
          this.messageTarget.innerHTML = '<div class="text-success">Success! Payment received.</div>';
          break;

        case 'processing':
          this.messageTarget.innerHTML = "<div class='text-warning'>Payment processing. We'll update you when payment is received.</div>";
          break;

        case 'requires_payment_method':
          this.messageTarget.innerHTML = '<div class="text-danger">Payment failed. Please try another payment method.</div>';
          break;

        default:
          this.messageTarget.innerHTML = '<div class="text-danger">Something went wrong.</div>';
          break;
      }
    });
  }
}
