import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ['nicheValueContainer', 'fictionValue', 'nicheOptions']

  connect() {
    this.updateNicheOptions()
  }

  updateNicheOptions() {
    let fiction = this.fictionValueTarget.checked ? 1 : 0;
    let values = '';

    console.log(fiction)

    if (fiction == 1) {
      values = JSON.parse(this.nicheValueContainerTarget.dataset.nicheFictionValues)
    } else {
      values = JSON.parse(this.nicheValueContainerTarget.dataset.nicheNonFictionValues)
    }

    values.unshift('Select Niche/Genre')

    let options = values.map(value => {
      return `<option value="${value}">${value}</option>`
    }).join('')

    this.nicheOptionsTarget.innerHTML = options
  }
}
