import { Controller } from "stimulus"

const BAR_DIV = document.querySelector('.upload-progress-div');
const BAR = document.querySelector('.upload-progress-bar');

export default class extends Controller {
  static targets = [
    'filename',
    'fileDesciption'
  ]

  connect() {}
  initialize() {}

  /*
  * Resets the uploader modal and empties file input and other text fields
  * Does not take any params
  * @return [null]
  */
  resetFileUploadModal() {
    let that = this;
    let input = document.querySelector('input[name=mp3_file]');

    const myTimeout = setTimeout(function() {
      $(BAR_DIV).addClass('d-none');
      $(BAR).attr('aria-valuenow', 0);
      $(BAR).css('width', '0%');
      $(BAR).text('');

      input.value = null;
      // Don't know why these two targets do not work
      // that.filenameTarget.value = ''
      // that.fileDesciptionTarget.value = ''

      document.querySelector('input[name=name]').value = '';
      document.querySelector('textarea[name=description]').value = '';
    }, 3000);
  }

  /*
  * Uploads the narration files using XHR
  * It also shows the uploading progress in a progress bar
  * Does not take any params
  * @return [null]
  */
  uploadFiles(event) {
    event.preventDefault();
    let that = this;

    const uploadProgressHandler = (event) => {
      let totalPorgress = Math.trunc(event.loaded / event.total * 100);

      $(BAR_DIV).removeClass('d-none');
      $(BAR).attr('aria-valuenow', totalPorgress);
      $(BAR).css('width', `${totalPorgress}%`);
      $(BAR).text(`${totalPorgress}%`);
    }

    const loadHandler = (event) => {
        // $("#status").html(event.target.responseText);
        $(BAR).attr('aria-valuenow', 0);
        $(BAR).css('width', '0%');
    }

    const errorHandler = (event) => {
        // $("#status").html("Upload Failed");
    }

    const abortHandler = (event) => {
        // $("#status").html("Upload Aborted");
    }

    let form = $('#narrationFilesUploadForm')[0];
    let fileInput = document.querySelector('input[name=mp3_file]');

    if(fileInput.value != '') {
      setupAjax();
      $.ajax({
        url: "/narration_files/",
        method: "POST",
        dataType: 'json',
        data: new FormData(form),
        processData: false,
        contentType: false,
        xhr: function () {
            var xhr = new window.XMLHttpRequest();
            xhr.upload.addEventListener("progress",
                uploadProgressHandler,
                false
            );
            xhr.addEventListener("load", loadHandler, false);
            xhr.addEventListener("error", errorHandler, false);
            xhr.addEventListener("abort", abortHandler, false);

            return xhr;
        },
        success: function (data) {
          console.log(data);
          $('.narration-files')[0].innerHTML = data['html'];
          $('#uploadNarrationFileModal').modal('hide');
          that.resetFileUploadModal();
        },
      });
    } else {
      alert('Please select a file to upload!');
    }
  }
}
