import { Controller } from "stimulus"

export default class extends Controller { 
  static targets = ["order"] 
  connect() {  
    let orderId = this.orderTarget.dataset.id;
    $.ajax({
      type: "GET",
      url: `/team_order_conversations/${orderId}`,
      success: response => {
        $("#messages-classic-shadow").find("#conversation_container").html(response);
  
        // Styling chat box with id="kt_chat_content"
        let isKTappChatInit = false;
        setTimeout(function () {
          KTAppChat.init();
          $("#team_chat_panel_btn").on("shown.bs.tab", function () {
            if (!isKTappChatInit) {
              KTAppChat.init();
              isKTappChatInit = true;
            }
          });
        }, 3000);
      },
      error: err => {
        console.log(`conversations_controller.js error: ${err}`)
      }
    });
  }
}
