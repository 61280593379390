import { Controller } from "stimulus";

export default class extends Controller {
  static targets = [
    'writers',
    'translationWriters',
    'editors',
    'translationEditors',
    'designers',
    'illustrators',
    'narrators',
    'totalReceived',
    'rejected',
    'passed',
    'testSent',
    'awaitingReview',
    'startDate',
    'endDate',
    'selectPosition',
    'chartAppStats',
    'chartAppProcessed',
    'chartAppRegions',
    'chartAppReferrals'
  ];

  initialize(){
    this.targetNames = [
      'writers',
      'translationWriters',
      'editors',
      'translationEditors',
      'designers',
      'illustrators',
      'narrators',
      'totalReceived',
      'rejected',
      'passed',
      'testSent',
      'awaitingReview'
    ];
    this.chartOne = new ApexCharts(this.chartAppStatsTarget, this.getOptions());
    this.chartTwo = new ApexCharts(this.chartAppProcessedTarget, this.getOptions());
    this.chartThree = new ApexCharts(this.chartAppRegionsTarget, this.getOptions());
    this.chartFour = new ApexCharts(this.chartAppReferralsTarget, this.getOptions());
  }

  connect() {
    this.chartOne.render();
    this.chartTwo.render();
    this.chartThree.render();
    this.chartFour.render();

    this.loadData();
  }

  /**
   * Loads order data to be displayed
   */
  loadData() {
    let startDate = this.startDateTarget.value;
    let endDate = this.endDateTarget.value;
    let position = this.selectPositionTarget.value;

    var that = this;
    setupAjax();
    $.ajax({
      url: "/analytics/freelancer_apps_overview/stats",
      method: "POST",
      data: {
        search: {
          start_date: startDate,
          end_date: endDate,
          position: position
        },
      },
      dataType: "json",
      success: function (data) {
        that.updateStats(data.stats, that);
        that.updateCharts(data, that);
      },
    });
  }

  /**
   * Updates the stats data to be displayed for the user
   *
   * @param {hash} stats contains freelancer applications statstics data
   */
  updateStats(stats, that) {
    that.targetNames.forEach((item, i) => {
      let target = item + 'Target';
      that[target].innerHTML = that.getStatHTMLRender(stats[i]);
    });
  }

  /*
  * Helper method to update all the charts for the page
  */
  updateCharts(data, that) {
    that.updateChartOne(data);
    that.updateChartTwo(data);
    that.updateChartThree(data);
    that.updateChartFour(data);
  }

  /*
  * Helper method to update the first chart of the page
  *   that shows metrics for role based application stats
  * @param {hash} data, directly coming from the server to render
  */
  updateChartOne(data) {
    let seriesData = [], labelData = [];
    for(var i = 0; i < 7; i++){
      seriesData.push(data['stats'][i]);
    }
    let colors = ['#008ffc', '#00e397', '#ffb01a', '#ff4560', '#775dd0', '#ff3399', '#009999'];
    labelData = ['Writers', 'Translation Writers', 'Editors', 'Translation Editors', 'Designers', 'Illustrators', 'Narrators'];
    this.chartOne.updateOptions(
      this.getOptions(
        seriesData,
        labelData,
        colors
      )
    );
  }

  /*
  * Helper method to update the second chart of the page
  *   that shows metrics for processed applications stats
  * @param {hash} data, directly coming from the server to render
  */
  updateChartTwo(data) {
    let seriesData = [], labelData = [];
    for(var i = 8; i < 12; i++){
      seriesData.push(data['stats'][i]);
    }
    let colors = ['#ff4560', '#00e397', '#8E44AD', '#F4D03F'];
    labelData = ['Rejected', 'Passed', 'Test Sent', 'Awaiting Review'];
    this.chartTwo.updateOptions(
      this.getOptions(
        seriesData,
        labelData,
        colors
      )
    );
  }

  // https://stackoverflow.com/questions/1484506/random-color-generator
  /*
  * Helper method to generate random color code
  * @return [String] color code
  */
  getRandomColor() {
    var letters = '0123456789ABCDEF';
    var color = '#';
    for (var i = 0; i < 6; i++) {
      color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
  }

  /*
  * Ref: https://dev.to/rajnishkatharotiya/generate-unique-color-code-in-javascript-3h06
  */
  getUniqColor() {
    let n = (Math.random() * 0xfffff * 1000000).toString(16);
    return '#' + n.slice(0, 6);
  }

  /*
  * Helper method to update the third chart of the page
  *   that shows metrics for applicants' regions stats
  * @param {hash} data, directly coming from the server to render
  */
  updateChartThree(data) {
    let seriesData = [], labelData = [], colors = [];
    for(const val in data['regions']) {
      seriesData.push(parseInt(`${data['regions'][val]}`));
      labelData.push(`${val}`);
      colors.push(this.getUniqColor());
    }

    this.chartThree.updateOptions(
      this.getOptions(
        seriesData,
        labelData,
        colors
      )
    );
  }

  /*
  * Helper method to update the third chart of the page
  *   that shows metrics for applicants' regions stats
  * @param {hash} data, directly coming from the server to render
  */
  updateChartFour(data) {
    let seriesData = [], labelData = [], colors = [];
    for(const val in data['referrals']) {
      seriesData.push(parseInt(`${data['referrals'][val]}`));
      labelData.push(`${val}`);
      colors.push(this.getRandomColor());
    }

    this.chartFour.updateOptions(
      this.getOptions(
        seriesData,
        labelData,
        colors
      )
    );
  }

  /**
   * Gets the html render for the stat
   *
   * @param {integer} stat the stat for rendering
   */
  getStatHTMLRender(stat) {
    return (
      '<span class="label label-xl label-light label-inline my-lg-0 my-2 text-dark-50 font-weight-bolder">' +
      stat +
      "</span>"
    );
  }

  /*
  * Helper method to update the main element of the charts
  * @param {Array} sereisData, is the array to show overview metrics in a pie chart
  * @param {Array} labelData, is the array to show label for the pie chart
  */
  getOptions(seriesData = [], labelData = [], colors= []) {
    return {
        colors: colors,
        series: seriesData,
        chart: {
          width: 480,
          type: 'pie',
        },
        labels: labelData,
        responsive: [{
          breakpoint: 480,
          options: {
            chart: {
              width: 400
            },
            legend: {
              position: 'bottom'
            }
          }
        }]
    };
  }
}
