import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [
    "startDate",
    "endDate",
    "chart",
    "totalPointsRewarded",
    "totalPointsUsed",
    "totalPointsChange",
    "totalPointsRewardedValue",
    "totalPointsUsedValue",
    "totalPointsChangeValue"
  ]

  connect() {
    this.chart = new ApexCharts(this.chartTarget, this.getOptions());
    this.chart.render();

    this.loadData();
  }

  /**
   * Loads the Reward Points data to be displayed
   *
   */
  loadData() {
    let startDate = this.startDateTarget.value;
    let endDate = this.endDateTarget.value;

    var that = this;
    setupAjax();
    $.ajax({
      url: "/analytics/reward_points/stats",
      method: "POST",
      data: {
        reward_points: {
          start_date: startDate,
          end_date: endDate
        }
      },
      dataType: "json",
      success: function (data) {
       that.updateChart(data.chart_data);
       that.updateStats(data.stats);
      }
    });
  }

  /**
   * Updates the stats data to be displayed for the user
   *
   * @param {hash} stats containing the reward points data to be displayed
   *    [totalPointsRewarded, totalPointsUsed, totalPointsChange, totalPointsRewardedValueTarget,
   *     totalPointsUsedValueTarget, totalPointsChangeValyeTarget]
   */
  updateStats(stats) {
    this.totalPointsRewardedTarget.innerHTML = this.getStatHTMLRender(stats[0], false);
    this.totalPointsUsedTarget.innerHTML = this.getStatHTMLRender(stats[1], false);
    this.totalPointsChangeTarget.innerHTML = this.getStatHTMLRender(stats[2], false);
    this.totalPointsRewardedValueTarget.innerHTML = this.getStatHTMLRender(stats[3], true);
    this.totalPointsUsedValueTarget.innerHTML = this.getStatHTMLRender(stats[4], true);
    this.totalPointsChangeValueTarget.innerHTML = this.getStatHTMLRender(stats[5], true);
  }

  /**
   * Updates the chart data to be displayed for the user
   *
   * @param {hash} chartData containing the reward points data to be displayed
   *    {date: [customerSignups, customerAffiliateSignups, jobPostings]}
   */
  updateChart(chartData) {
    var totalPointsRewarded = [];
    var totalPointsUsed = [];
    var totalPointsChange = [];
    var dates = [];

    // Get chart data and populate the relevant arrays to prepare
    // for display
    $.each(chartData, function(key, value) {
      dates.push(key);
      totalPointsRewarded.push(value[0]);
      totalPointsUsed.push(value[1]);
      totalPointsChange.push(value[2]);
    });

    this.chart.updateOptions(
      this.getOptions(
        totalPointsRewarded,
        totalPointsUsed,
        totalPointsChange,
        dates
      )
    );
  }

  /**
   * Gets the options hash for the ApexChart
   *
   * @param {hash} totalPointsRewarded (optional) the data points for total points rewarded
   * @param {hash} totalPointsUsed (optional) the data points for total points used
   * @param {hash} totalPointsChange (optional) the data points for total points changed
   *
  **/
  getOptions(totalPointsRewarded = [], totalPointsUsed = [], totalPointsChange = [], dates = []) {
    var options = {
      chart: {
        type: 'bar',
        height: '300px'
      },
      series: [
        {
          name: 'Total Dibbly Rewards Earned',
          data: totalPointsRewarded
        },
        {
          name: 'Total Dibbly Rewards Used',
          data: totalPointsUsed
        },
        {
          name: 'Total Dibbly Rewards Change',
          data: totalPointsChange
        },
      ],
      xaxis: {
        categories: dates
      }
    }

    return options;
  }

  /**
   * Gets the html render for the stat, to be displayed to user
   *
   * @param {integer} stat the stat for rendering
   */
  getStatHTMLRender(stat, isMoney) {
    let statDisplay = stat;
    if (isMoney) { statDisplay = '$' + stat + ' USD' }

    return '<span class="label label-xl label-light label-inline my-lg-0 my-2 text-dark-50 font-weight-bolder">' + statDisplay + '</span>';
  }
}
