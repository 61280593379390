import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [
    "orderNumber",
    "jobPostingOrderNumber",
    "wordCount",
    "wordCountText",
    "customer",
    "customerNote",
    "customerRequirements",
    "placedOn",
    "title",
    "subtitle",
    "startedOn",
    "dueOn",
    "fulfilledOn",
    "package",
    "writer",
    "editor",
    "managerIcon",
    "requirementsFile",
    "requests",
    "id",
    "assignmentType",
    "openPage",
    "orderPreviewDetails",
    "openCustomerProfile",
    "plagReport",
    "managedType",
    "manager",
    "freelancerSelection",
    "freelancerNote",
    "schedulingByManager",
    "zoomCall",
    "progressUpdates",
    "additionalServices"
  ]

  connect() {
    console.log("Order Preview controller is now connected");
  }

  displayOrderPreview(event) {
    var orderId = event.target.dataset.id;

    this.pullOrderPreviewData(orderId);
  }

  pullOrderPreviewData(id) {
    // Hold current object
    var that = this;

    // Prepare Ajax and pull order data
    setupAjax();
    $.ajax({
      url: "/orders/order_preview_data",
      method: "GET",
      data: {
        order: {
          id: id
        }
      },
      dataType: "json",
      success: function (data) {
        // Load order data for display
        that.showOrderData(data);
      }
    });
  }

  showOrderData(data) {
    if (data.requestedWriter){
      var writerLabel = document.createElement('dt')
      writerLabel.className = "col-md-3"
      writerLabel.innerText = "Requested Writer"
      var writerValue = document.createElement('dd')
      writerValue.className = "col-md-9 text-danger"
      writerValue.innerText = "Yes"
      this.orderPreviewDetailsTarget.prepend(writerValue);
      this.orderPreviewDetailsTarget.prepend(writerLabel);
    }

    if (data.illustration_count > 0) {
      this.wordCountTarget.innerHTML = data.illustration_count
      this.wordCountTextTarget.innerHTML = 'Illustration Count'
    } else {
      this.wordCountTarget.innerHTML = data.word_count
      this.wordCountTextTarget.innerHTML = 'Word Count'
    }

    if (!!data.job_posting_order_number) {
      this.jobPostingOrderNumberTarget.innerHTML = `Project Posting: ${data.job_posting_order_number}`;
    } else {
      this.jobPostingOrderNumberTarget.innerHTML = '';
    }

    if(!!data.attached_plagiarism_report) {
      this.plagReportTarget.innerHTML = "- Plagiarism Report attached <i class='fas fa-check text-success'></i>"
    } else {
      this.plagReportTarget.innerHTML = ''
    }

    this.orderNumberTarget.innerHTML = data.order_number;
    this.customerTarget.innerHTML = data.customer;
    this.customerNoteTarget.textContent = data.customer_note;
    this.customerRequirementsTarget.textContent = data.customer_requirements;
    this.placedOnTarget.innerHTML = data.ordered_date;
    this.titleTarget.textContent = data.title;
    this.subtitleTarget.textContent = data.subtitle;
    this.startedOnTarget.innerHTML = data.start_date ? data.start_date : "Not Set";
    this.dueOnTarget.innerHTML = data.due_date ? data.due_date : "Not Set";
    this.fulfilledOnTarget.innerHTML = data.fulfilled_date ? data.fulfilled_date : "Not Set";
    this.packageTarget.innerHTML = data.package;
    this.writerTarget.innerHTML = data.writer ? data.writer : "Not Assigned";
    this.editorTarget.innerHTML = data.editor ? data.editor : "Not Assigned";
    this.requestsTarget.innerHTML = data.requests;
    this.requirementsFileTarget.innerHTML = data.requirements ? "Yes" : "No";
    this.idTarget.innerHTML = data.id;
    this.assignmentTypeTarget.innerHTML = data.assignment_type

    if (data.manager !== null) {
      this.managerIconTarget.innerHTML = data.manager;
    } else {
      var managerElement = document.getElementById('manager');
      if ( managerElement !== null) {
        managerElement.remove();
      }
    }

    if(data.managed_service !== null) {
      this.managedTypeTarget.innerHTML = data.managed_service.managedType;
      this.managerTarget.innerHTML = data.manager;
      this.freelancerSelectionTarget.innerHTML = data.managed_service.freelancerSelection;
      this.freelancerNoteTarget.innerHTML = data.managed_service.freelancerNote;
      this.schedulingByManagerTarget.innerHTML = data.managed_service.schedulingByManager;
      this.zoomCallTarget.innerHTML = data.managed_service.zoomCall;
      this.progressUpdatesTarget.innerHTML = data.managed_service.progressUpdates;
      this.additionalServicesTarget.innerHTML = data.managed_service.additionalServices;
    } else {
      // let managedElms = document.getElementsByClassName('managed-info');
      document.querySelectorAll(".managed-info").forEach(el => el.remove());
    }

    this.setOrderPageURL(data.id, data.open_new_tab);
    this.setOrderCustomerProfileURL(data.customer_id, data.open_new_tab);
  }

  setOrderPageURL(orderId, openNewTab) {
    var urlTarget = openNewTab ? "_blank" : "_self";

    this.openPageTarget.href = window.location.origin + "/orders/" + orderId;
    this.openPageTarget.target = urlTarget;
  }

  setOrderCustomerProfileURL(customerId, openNewTab) {
    var urlTarget = openNewTab ? "_blank" : "_self";

    this.openCustomerProfileTarget.href = window.location.origin + "/customers/" + customerId;
    this.openCustomerProfileTarget.target = urlTarget;
  }

}
