import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "total", "employees", "customers" ]

  connect() {
    this.pullTotals();

    var that = this;
    window.setInterval(function() {
      that.pullTotals();
    }, 25000);
  }

  pullTotals() {
    var that = this;

    setupAjax();
    $.ajax({
      url: "/admin/online_users_total",
      method: "GET",
      dataType: "json",
      success: function (data) {
        that.totalTarget.innerHTML = data.total;
        that.employeesTarget.innerHTML = data.employees;
        that.customersTarget.innerHTML = data.customers;
      }
    });
  }
}
