import { Controller } from "stimulus"

export default class extends Controller {
  static targets= [ "title", 
                    "reallocationActivityOrderId", 
                    "status", 
                    "warning", 
                    "managedBy",
                    "ReallocationId"]

  connect() {
    console.log("Reallocations connected!");
  }


  // Updates reallocation managed status
  //
  // @return javascript update of the element containing the status of the changed reallocation
  //    activitity without reloading the view
  updateReallocationActivity() {
    var that = this;

    setupAjax();
    $.ajax({
      url: "/admin/update_reallocation",
      method: "POST",
      data: {
        reallocation_activity: {
          id: that.reallocationActivityOrderIdTarget.value,
          status: that.statusTarget.value.toLowerCase(),
          managed_by: that.managedByTarget.value
        }
      },
      success: function (data) {
        var reallocation_activity_status = $(`#status-${that.reallocationActivityOrderIdTarget.value}`)[0]
        reallocation_activity_status.innerText = that.statusTarget.value

        var reallocationBtn = reallocation_activity_status.parentElement.parentElement.firstElementChild.firstElementChild
        reallocationBtn.dataset.reallocationSelectedOption = data.status
      },
      error: function () {
        alert("Something went wrong, Please try again");
      },
    });
    
    $('#reallocationActivityModal').modal('toggle')
  }

  // Toggles reallication activity modal to display and update details
  displayModal(event) {
    this.statusTarget.selectedIndex = event.currentTarget.dataset.reallocationSelectedOption;
    var reallocationActivityId = event.currentTarget.dataset.reallocationActivityId
    var reallocationActivityTitle = event.currentTarget.dataset.reallocationTitle.replaceAll('%20',' ');
    this.reallocationActivityOrderIdTarget.value = reallocationActivityId
    this.titleTarget.innerText = `Order: ${reallocationActivityTitle}`
    
    $('#reallocationActivityModal').modal('toggle')
  }

  // displays a warning message to the management that the order will be cancelled if they mark the
  //    reallocation activity managed status as cancelled
  displayOrderCancellationWarning(event){
    if (event.srcElement.value == "Cancelled"){
      this.warningTarget.innerText = "* Changing status to cancelled will cancel the order!"
    } else {
      this.warningTarget.innerText = ""
    }
  }
}
