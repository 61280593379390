import { Controller } from "stimulus";

export default class extends Controller {
  static targets = [
    "totalEmployees",
    "activeEmployees",
    "inactiveEmployees",
    "newEmployees",
    "offBoarded",
    "newOffBoarded",
    "restricted",
    "passedTrial",
    "startDate",
    "endDate",
    "selectPosition",
    "selectTier",
    "chartEmpStats",
    "chartEmpRegions",
    "chartNewEmpStats",
    "chartUnavailableEmpStats",
    "chartOffboardedEmpStats",
    "unavailableEmployees",
    "selfUnavailableEmployees",
    "adminUnavailableEmployees",
    "offBoardedEmployees"
  ];

  initialize(){
    this.targetNames = [
      "totalEmployees",
      "activeEmployees",
      "inactiveEmployees",
      "restricted",
      "newEmployees",
      "offBoarded",
      "newOffBoarded",
      "passedTrial",
      "unavailableEmployees",
      "selfUnavailableEmployees",
      "adminUnavailableEmployees",
      "offBoardedEmployees"
    ];

    this.chartOne = new ApexCharts(this.chartEmpStatsTarget, this.getOptions());
    this.chartTwo = new ApexCharts(this.chartEmpRegionsTarget, this.getOptions());
    this.chartThree = new ApexCharts(this.chartNewEmpStatsTarget, this.getOptions());
    this.chartFour = new ApexCharts(this.chartUnavailableEmpStatsTarget, this.getOptions());
    this.chartFive = new ApexCharts(this.chartOffboardedEmpStatsTarget, this.getOptions());
  }

  connect() {
    this.chartOne.render();
    this.chartTwo.render();
    this.chartThree.render();
    this.chartFour.render();
    this.chartFive.render();
    this.loadData();
  }

  /**
   * Loads order data to be displayed
   */
  loadData() {
    let startDate = this.startDateTarget.value;
    let endDate = this.endDateTarget.value;
    let position = this.selectPositionTarget.value;
    let tier = this.selectTierTarget.value;

    var that = this;
    setupAjax();
    $.ajax({
      url: "/analytics/hire_releases_stats/stats",
      method: "POST",
      data: {
        search: {
          start_date: startDate,
          end_date: endDate,
          position: position,
          tier: tier
        },
      },
      dataType: "json",
      success: function (data) {
        console.log(data);
        that.updateStats(data.stats, that);
        that.updateCharts(data, that);
      },
    });
  }

  /**
   * Updates the stats data to be displayed for the user
   *
   * @param {hash} stats contains order statstic date
   */
  updateStats(stats, that) {
    that.targetNames.forEach((item, i) => {
      let target = item + 'Target';
      that[target].innerHTML = that.getStatHTMLRender(stats[i]);
    });
  }

  /*
  * Helper method to update all the charts for the page
  */
  updateCharts(data, that) {
    that.updateChartOne(data);
    that.updateChartTwo(data);
    that.updateChartThree(data);
    that.updateChartFour(data);
    that.updateChartFive(data);
  }

  /*
  * Helper method to update the first chart of the page
  *   that shows metrics for role based application stats
  * @param {hash} data, directly coming from the server to render
  */
  updateChartOne(data) {
    let seriesData = [], labelData = [];
    for(var i = 1; i < 4; i++){
      seriesData.push(data['stats'][i]);
    }
    let colors = ['#ff4560' ,'#008ffc', '#7fbc22'];
    labelData = [`Active - ${data['stats'][1]}`, `Inactive - ${data['stats'][2]}`, `Restricted - ${data['stats'][3]}`];
    this.chartOne.updateOptions(
      this.getOptions(
        seriesData,
        labelData,
        colors
      )
    );
  }

  /*
  * Ref: https://dev.to/rajnishkatharotiya/generate-unique-color-code-in-javascript-3h06
  */
  getUniqColor() {
    let n = (Math.random() * 0xfffff * 1000000).toString(16);
    return '#' + n.slice(0, 6);
  }

  /*
  * Helper method to update the third chart of the page
  *   that shows metrics for applicants' regions stats
  * @param {hash} data, directly coming from the server to render
  */
  updateChartTwo(data) {
    let seriesData = [], labelData = [], colors = [];
    for(const val in data['regions']) {
      seriesData.push(parseInt(`${data['regions'][val]}`));
      labelData.push(`${val}` + ' - ' + `${data['regions'][val]}`);
      colors.push(this.getUniqColor());
    }

    this.chartTwo.updateOptions(
      this.getOptions(
        seriesData,
        labelData,
        colors
      )
    );
  }

  updateChartFour(data) {
    let seriesData = [], labelData = [], colors = [];
    for(const val in data['unavailable_reasons']) {
      seriesData.push(parseInt(`${data['unavailable_reasons'][val][1]}`));
      labelData.push(`${data['unavailable_reasons'][val][0]} - ${data['unavailable_reasons'][val][1]}`);
      colors.push(this.getUniqColor());
    }

    this.chartFour.updateOptions(
      this.getOptions(
        seriesData,
        labelData,
        colors
      )
    );
  }

  updateChartFive(data) {
    let seriesData = [], labelData = [], colors = [];
    for(const val in data['offboard_reasons']) {
      seriesData.push(parseInt(`${data['offboard_reasons'][val][1]}`));
      labelData.push(`${data['offboard_reasons'][val][0]} - ${data['offboard_reasons'][val][1]}`);
      colors.push(this.getUniqColor());
    }

    this.chartFive.updateOptions(
      this.getOptions(
        seriesData,
        labelData,
        colors
      )
    );
  }

  /*
  * Helper method to update the first chart of the page
  *   that shows metrics for role based application stats
  * @param {hash} data, directly coming from the server to render
  */
  updateChartThree(data) {
    let seriesData = [], labelData = [];
    seriesData.push(data['stats'][4]);
    seriesData.push(data['stats'][7]);
    seriesData.push(data['stats'][6]);
    seriesData.push(data['stats'][5]);

    let colors = ['#ff4560', '#1a75ff', '#7fbc22', '#ffbb44'];
    labelData = [`Trial Freelancers - ${data['stats'][4]}`, `Passed Trial - ${data['stats'][7]}`, `Trials Offboarded - ${data['stats'][6]}`, `Offboarded - ${data['stats'][5]}`];
    this.chartThree.updateOptions(
      this.getOptions(
        seriesData,
        labelData,
        colors
      )
    );
  }

  /**
   * Gets the html render for the stat
   *
   * @param {integer} stat the stat for rendering
   */
  getStatHTMLRender(stat) {
    return (
      '<span class="label label-xl label-light label-inline my-lg-0 my-2 text-dark-50 font-weight-bolder">' +
      stat +
      "</span>"
    );
  }

  /*
  * Helper method to update the main element of the charts
  * @param {Array} sereisData, is the array to show overview metrics in a pie chart
  * @param {Array} labelData, is the array to show label for the pie chart
  */
  getOptions(seriesData = [], labelData = [], colors= []) {
    return {
        colors: colors,
        series: seriesData,
        chart: {
          width: 480,
          type: 'pie',
        },
        labels: labelData,
        responsive: [{
          breakpoint: 480,
          options: {
            chart: {
              width: 400
            },
            legend: {
              position: 'bottom'
            }
          }
        }]
    };
  }
}
