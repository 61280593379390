import { Controller } from "stimulus"

export default class extends Controller {
  static targets= [ "extensionModalTitle",
                    "extensionOrderId",
                    "ExtensionId",
                    "extensionDetailsForm",
                    "noteToRequesterLabel",
                    "reasonDeniedcharCount",
                    "reasonLength",
                    "reasonNote" ]

  connect() {
    if (this.hasReasonNoteTarget){
      this.reasonNoteTarget.addEventListener("input", this.updateCharacterCounter.bind(this));
    }
  }

  // Fill the extension modal title with the order number
  //
  // @param extensionJson [JSON] to get the title
  //
  // @return [string] title of the modal
  fillExtensionRequestTitle(extensionJson){
    this.extensionModalTitleTarget.innerText = extensionJson.order_badge;
  }

  // Fill the extenion request form with the details of the extension requested
  //
  // @param extensionJson [JSON] to get the orderId and extensionId
  //
  // @return [string] fill the id of the order, fill the id of the order extension
  fillExtensionRequestForm(extensionJson){
    this.extensionOrderIdTarget.value = extensionJson.order_id;
    this.ExtensionIdTarget.value = extensionJson.extension_id;
  }

  // Fills the details of extenion for manager and customer view to help with decision making
  //
  // @param extensionJson [JSON] to get the order extension details
  //
  // @return [string] HTML of the order extension details and append modal
  fillExtensionDetails(extensionJson){
    var extensionDetails = $('#extension-details');
    var reason_requested = "N/A";

    if (extensionDetails.length > 0){
      extensionDetails.remove();
    };

    if (extensionJson.reason_requested){
      // replace ruby newline with paragraph breaks
      reason_requested = extensionJson.reason_requested.replace(/(\r\n|\n|\r)/gm,'<br>');
    }

    var html_string =`<label class="form-check-label" id="extension-details"
                        for="order_extension_id_${extensionJson.extension_id}">
                          <b>Requested On:</b> ${extensionJson.date}
                          <br><b>Requester:</b> ${extensionJson.employee_name}
                          <br><b>Extension Date:</b> ${extensionJson.days}
                          <br><b>For:</b> ${extensionJson.employee_position}
                          <br><b>Reason requested:</b>
                          <div style="overflow: auto; width:90%;">
                            ${reason_requested}
                          </div>
                      </label>`;
    this.extensionDetailsFormTarget.insertAdjacentHTML( 'afterbegin', html_string );
  }

  // Toggles required & hidden attributes for note to requester, triggered by a change if the extension
  //    request is approved/denied by the viewer
  toggleNoteToRequester(){
    this.reasonNoteTarget.required = this.reasonNoteTarget.hidden;
    this.reasonNoteTarget.hidden = !this.reasonNoteTarget.hidden;
    this.noteToRequesterLabelTarget.hidden = this.reasonNoteTarget.hidden;
    this.reasonDeniedcharCountTarget.hidden = this.reasonNoteTarget.hidden;
  }

  // Triggered by clicking the order extension "View" button, Fills in the all the details for the
  //    modal and displays it
  //
  // @param event [DOM event] to identify which order_extension is request to view
  //
  // @return change html and display the modal
  fillViewedExtension(event){
    var details = event.currentTarget.dataset.details;
    details = details.replaceAll('%20',' ');
    details = details.replaceAll(`%27`,`'`);

    var extensionJson = JSON.parse(details);
    var extensionRequestModal = $('#extensionRequestModal');

    this.fillExtensionRequestTitle(extensionJson);
    this.fillExtensionRequestForm(extensionJson);
    this.fillExtensionDetails(extensionJson);

    extensionRequestModal.modal();
  }

  // Displays the extension history modal triggered by viewer
  showExtensionsHistory(){
    var extensionsHistoryModal = $('#extensionHistoryModal');
    extensionsHistoryModal.modal();
  }

  // triggered by event listener for the text_area to count letters and update counter
  //
  // @return updates letter counter in the extenion forms
  updateCharacterCounter(event){
    var reasonCharacterCount = event.srcElement.value.length;
    var reasonLengthString = `${700 - reasonCharacterCount}`;
    this.reasonLengthTarget.innerText = reasonLengthString;
  }
}
