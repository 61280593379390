import { Controller } from "stimulus"

export default class extends Controller {

  static targets = [
    "systemNotifications",
    "userMessages",
    "systemMessagesContainer",
    "userMessagesContainer",
    "teamMessagesContainer"
  ];

  // Direct data-action="loadSystemMessages->notifications#getNotifications"
  // (requires the notifications controller to be loaded first)
  connect() {
    this.element.dispatchEvent(new CustomEvent("loadSystemMessages"));
  }

  showSystemMessages() {
    this.userMessagesTarget.classList.remove('notif-active');
    this.systemNotificationsTarget.classList.add('notif-active');

    this.userMessagesContainerTarget.classList.add('hidden');
    this.systemMessagesContainerTarget.classList.remove('hidden');
  }

  showUserMessages() {
    this.systemNotificationsTarget.classList.remove('notif-active');
    this.userMessagesTarget.classList.add('notif-active');

    this.systemMessagesContainerTarget.classList.add('hidden');
    this.userMessagesContainerTarget.classList.remove('hidden');
  }
}
