import { Controller } from "stimulus"
// pulls order info every 30 seconds
const ORDER_POLLING_INTERVAL = 30000;

export default class extends Controller {
  static targets = [
    "updateStatus",
    "updateCustomerDue",
    "updateForm",
    "updateSpinner",
    "updateBtn",
    "order",
    "style",
    "coverSelRadio"
  ]

  connect() {
    var ob = new MutationObserver(function() {
      let orderMenu = document.getElementById('kt_order_menu');
      if (typeof(orderMenu) != 'undefined' && orderMenu != null) {
        if (orderMenu.classList.contains('offcanvas-mobile-on')) {
          document.getElementById('kt_order_menu_toggle').click()
        }
      }
    });

    document.querySelectorAll('[role="tabpanel"]').forEach(function (el){
      ob.observe(el, {
        attributes: true,
        attributeFilter: ["class"]
      });
    });

    // Set NOTIFICATION_POLLING_DISABLED to 'true' in local-env.rb to disable
    if (this.hasOrderTarget && window.notificationPollingDisabled !== 'true') {
      // Start order interval timer, every 30 seconds.
      var that = this;
      window.setInterval(function () {
        that.pullLatestOrderState();
      }, ORDER_POLLING_INTERVAL);
    }
  }

  // Pulls the latest order from the server to prompt user if refresh is needed
  pullLatestOrderState(event) {
    var that = this;

    this.orderID = this.orderTarget.dataset.orderid;
    this.orderState = this.orderTarget.dataset.orderstate;

    setupAjax();
    $.ajax({
      url: "/orders/state_out_of_date",
      method: "GET",
      data: {
        order: {
          id: that.orderID,
          order_state: that.orderState
        },
      },
      dataType: "json",
      success: function (data) {
        if(!data.states_match && that.hasStyleTarget) {
          if(that.styleTarget.classList.value.includes('d-none')) {
            if(confirm('Order out of date. Confirm to refresh.')){window.location.reload();}
          }
          that.styleTarget.classList.remove('d-none');
        }
      },
    });
  }

  displayUpdateOrder(event) {
    var id = event.target.dataset.id;
    this.pullOrderData(id);
  }

  pullOrderData(id) {
    // Hold current object
    var that = this;

    // Prepare Ajax and pull order data
    setupAjax();
    $.ajax({
      url: "/orders/update_data",
      method: "GET",
      data: {
        order: {
          id: id
        }
      },
      dataType: "json",
      success: function (data) {

        // Load order data for display
        that.updateSpinnerTarget.style.display = 'block';
        that.showData(data);
        that.updateFormTarget.style.display = 'block';
        that.updateBtnTarget.disabled = false;
        that.updateSpinnerTarget.style.display = 'none';
      }
    });
  }

  showData(data) {
    this.displayUpdateStatus(data);
    this.displayCustomerDue(data);
  }

  fileUploadReady() {
    var fileUploadField = this.targets.find("file_upload");
    var btnFileUpload =  $('#' + fileUploadField.id + '-btn')[0];
    var imgPreview =  $('#img_preview')[0];

    if (fileUploadField.value !== "") {
      try {
        btnFileUpload.disabled = false;
        imgPreview.style.display = "block";
      }
      catch (exception_var) {
        console.log("Error in orders controller: " + exception_var);
      }
    } else {
      btnFileUpload.disabled = true;
    }
  }

  displayUpdateStatus(data) {
    var that = this;

    data.states.forEach(function(state) {
      var option = new Option(state.state, state.id);
      option.innerHTML = state.state;
      $(that.updateStatusTarget).append(option);
    });

    $(that.updateStatusTarget).val(data.current_state.id);
  }

  displayCustomerDue(data) {
    $(this.updateCustomerDueTarget).val(data.customer_due);
  }

  disableExportDocBtn(event) {
    event.currentTarget.disabled = true;
    event.currentTarget.className = 'btn btn-light-grey'
    event.currentTarget.form.submit();
  }

  plagiarismProviderSelected(event) {
    if (event.target.value === 'unicheck') {
      $('#plag-score-field').hide()
    } else {
      $('#plag-score-field').show()
    }
  }

  coverSelRadioUpdate(event) {
    document.getElementsByClassName('coverSelRadio').forEach((item, i) => {
      item.disabled = event.currentTarget.value == 'true' ? false : true;
    });
  }

  selectApprovedDesign(event) {
    document.getElementById('order_approved_cover_num').value = event.currentTarget.value;
  }
}
