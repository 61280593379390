import { Controller } from "stimulus";

const COUNTER_ID = "#length-warning";
const INPUT_ID = "#input_text";
const MIN_LENGTH = 30;
const TOO_LONG_MESSAGE = "<span style='color:red'><b>Over limit.</b></span>";
const TOO_SHORT_MESSAGE = "<b>Under Limit.</b>";

const countLengthWords = (input) => {
  let length = 0;
  let limitType;
  let minLength = 0;

  if(input.dataset.characterLimit === 'true') {
    length = input.value.length;
    limitType = 'characters';
  } else {
    length = input.value.split(/[\s]+/).length; // Count words by splitting at spaces
    limitType = 'words';
  } 

  // use given min length or get default
  if(input.dataset.minLength != null) {
    minLength = input.dataset.minLength;
  } else {
    minLength = MIN_LENGTH;
  } 

  let page = input.parentElement;
  let maxLength = input.dataset.maxLength;

  let wordsLeft = maxLength - length;

  if (input.value.trim() == "") {
    page.querySelector(COUNTER_ID).innerHTML =
      TOO_SHORT_MESSAGE + ` At least <b>${minLength}</b> more ${limitType}`;
  } else if (wordsLeft > (maxLength - minLength)) {
    page.querySelector(COUNTER_ID).innerHTML =
      TOO_SHORT_MESSAGE + ` At least <b>${wordsLeft - (maxLength - minLength)}</b> more ${limitType}`;
  } else if (wordsLeft >= 0) {
    page.querySelector(COUNTER_ID).innerHTML = `<b>${wordsLeft.toString()}</b> ${limitType} left`;
  } else if (wordsLeft < maxLength) {
    page.querySelector(COUNTER_ID).innerHTML = TOO_LONG_MESSAGE;
  }

  return wordsLeft;
};

function trimExtraWords(values, maxLength) {
  let new_values = [];
  for(let i = 0 ; i < maxLength; i++) {
    new_values.push(values[i]);
  }
  return new_values.join(' ');
}

export default class extends Controller {
  connect() {
    // Only change text if user has typed something
    if (this.element.querySelector(INPUT_ID).innerHTML !== '') {
      countLengthWords(this.element.querySelector(INPUT_ID));
    }

    this.element
      .querySelector(INPUT_ID)
      .addEventListener("input", this.validateInput);
  }

  disconnect() {
    this.element
      .querySelector(INPUT_ID)
      .removeEventListener("input", this.validateInput);
  }

  validateInput(event) {
    let wordsLeft = countLengthWords(this);
    if(this.dataset.characterLimit != 'true') {
      if (wordsLeft <= 0) {
        event.currentTarget.value = trimExtraWords(event.currentTarget.value.trim().split(/\s+/), this.dataset.maxLength);
      }
    } 
  }

  errorMessage(){
    let input = this.element.querySelector(INPUT_ID)
    let wordsLeft = countLengthWords(input);
    let minLength = 0;
    if(input.dataset.minLength != null) {
      minLength = input.dataset.minLength;
    } else {
      minLength = MIN_LENGTH;
    } 

    if (wordsLeft >= 0 && wordsLeft <= minLength) {
      $("#submitApplicationModal").modal();
    } else {
      $("#writing-sample-error").removeClass('d-none')
    }
  }
}


