import TextareaAutogrow from "stimulus-textarea-autogrow"

export default class extends TextareaAutogrow {

  autogrow() {
    super.autogrow()
    // The container div for messages:
    //
    // <div data-target="chat-panel-component--component.messages" class="messages" style="margin-bottom: 100px">
    let chatPanelDiv = this.element.parentElement.parentElement.firstElementChild.lastElementChild;

    // Default margin for the above message container
    let defaultPaddingBottom = 50;
    let paddingBottom = defaultPaddingBottom + this.element.scrollHeight;

    chatPanelDiv.style['padding-bottom'] = `${paddingBottom}px`;

    // skip unless textarea is expanded larger than default 50 + 39px height
    if (paddingBottom > 90) {
      if(chatPanelDiv.lastElementChild == null) { return }

      chatPanelDiv.lastElementChild.scrollIntoView();
    }
  }
}
