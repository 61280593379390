import { Controller } from "stimulus"

const error_color = 'red';
const valid_color = 'green';
const info_color = 'black';

export default class extends Controller {
    static targets = [ "photo", 'usernameAvlMsg' ]

    displayPreview(event) {
        const input = event.target
        const reader = new FileReader();
        reader.onload = (event) => {
            this.photoTarget.src = event.currentTarget.result;
        }
        reader.readAsDataURL(input.files[0])
    }

    /*
    * Checks username availability once customer user tries to set his username
    * Sends ajax request to the backend with the input username and returns back
    *   with available information
    * @param [Event] event object fires from javascript engine
    *
    * @return [Null]
    */
    checkUsernameAvailability(event) {
      let usernameValue = event.currentTarget.value;
      let avlTxtTarget = this.usernameAvlMsgTarget;
      let that = this;

      if (usernameValue !== '') {
        setupAjax();
        $.ajax({
          url: '/customer/access/check_username_availability',
          method: 'POST',
          dataType: 'json',
          data: { username: event.currentTarget.value },
          success: function(data) {
            if(data.available === true) {
                avlTxtTarget.style.color = valid_color;
                avlTxtTarget.innerHTML = '(Username is available)';
            } else {
              avlTxtTarget.style.color = error_color;
              avlTxtTarget.innerHTML = data.message;
            }
          }
        })
      } else {
        avlTxtTarget.style.color = info_color;
        avlTxtTarget.innerHTML = '(Optional, 3 character minimum)';
      }
    }
}
