import { Controller } from "stimulus"

export default class extends Controller {


  // Direct data-action="loadSystemMessages->notifications#getNotifications"
  // (requires the notifications controller to be loaded first)
  // create custom loadUserMessages event to load user-specific messages
  connect() {
    console.log('profile component controller connected');
    let url = '/customer/dashboard/newsfeed'

    setupAjax();
    $.ajax({ url: url, method: "GET" })
  }

  showSystemMessages() {
    this.element.dispatchEvent(new CustomEvent("loadSystemMessages"));
  }

  showUserMessages() {
    this.element.dispatchEvent(new CustomEvent("loadUserMessages"));
  }
}
