import { Controller } from "stimulus"

export default class extends Controller {
    static targets = [ "numberField" ]

    stepUpNumber(){
        this.numberFieldTarget.stepUp();
    }
    
    stepDownNumber(){
        this.numberFieldTarget.stepDown();
    }

}