import { Controller } from "stimulus";

export default class extends Controller {
  static targets = [
    "userMessageList",
    "teamMessageList",
    "updateList",
    "userBadge",
    "teamBadge",
    "updateBadge",
    "user",
  ];

  connect() {
    this.userId = this.userTarget.dataset.userid;
    this.playSound = this.userTarget.dataset.playSound;
    this.lastNotificationTime = this.userTarget.dataset.lastNotifTime;

    // Add new notifications to the top of the message list? (boolean-like string)
    this.notifSortNewest = (this.userTarget.dataset.notifSortNewest === "true");
    this.alertNewNotifs = true; // currently no user setting for this

    this.USER_MESSAGES_TYPE = "user_messages";
    this.TEAM_MESSAGES_TYPE = "team_messages";
    this.UPDATES_TYPE = "updates";

    this.setPages();
    this.setLoaded();
    this.setReads();

    // Start notification interval timer, every 30 seconds.
    var that = this;

    // Set NOTIFICATION_POLLING_DISABLED to 'true' in development.rb to disable
    // 30-second notification polling
    if (window.notificationPollingDisabled === 'true') {
      console.log('Notification Polling disabled');
    } else {
      window.setInterval(function () {
        that.pullLatestNotifications();
      }, 30000);
    }
  }

  /**
   * Sets the default values for pages
   */
  setPages() {
    this.userMessagePage = 1;
    this.teamMessagePage = 1;
    this.updatePage = 1;
  }

  /**
   * Sets the default values for notifications loaded
   */
  setLoaded() {
    this.userMessageLoaded = false;
    this.teamMessageLoaded = false;
    this.updateLoaded = false;
  }

  /**
   * Sets the default values for notifications read
   */
  setReads() {
    this.userMessagesRead = false;
    this.teamMessagesRead = false;
    this.updatesRead = false;
  }

  /**
   * Gets the first page of notifications from the server to be displayed
   * to the user
   *
   * @param {Event} event the event triggered
   */
  getNotifications(event) {
    let type = event.target.dataset.type;

    if (!this.isLoaded(type)) {
      this.loadNotifications(type, this.getPage(type));
    }

    this.setRead(type);
  }

  /**
   * Gets Notifications for the user notification type
   */
  getUserMessages() {
    let type = this.USER_MESSAGES_TYPE;

    if (!this.isLoaded(type)) {
      this.loadNotifications(type, this.getPage(type));
    }

    this.setRead(type);
  }


  /**
   * Loads more notifications from the server to be displayed to the user.
   * Should only be used when number of pages greater than 1.
   *
   * @param {Event} event the event triggered
   */
  loadMore(event) {
    event.stopPropagation();
    event.preventDefault();

    var type = event.target.dataset.type;
    var buttonRowElem = event.target.parentElement.parentElement;
    $(buttonRowElem).remove();

    this.loadNotifications(type, this.getPage(type));
  }

  /**
   * Updates the notifications read attribute to true, letting the system
   * know that the user has seen the notification.
   *
   * @param {Event} event the event triggered
   */
  setRead(type) {
    if (!this.hasRead(type)) {
      this.updateRead(type);
    }
  }

  /**
   * Clears all the notifications based on the type.
   *
   * @param {Event} event the event triggered
   */
  clearAllNotifications(event) {
    event.stopPropagation();
    event.preventDefault();

    var btn = event.target;
    var type = event.target.dataset.type;
    var that = this;

    btn.disabled = true;
    btn.innerHTML = "Clearing...";

    setupAjax();
    $.ajax({
      url: "/notifications/clear_all",
      method: "POST",
      data: {
        notification: {
          type: type,
        },
      },
      success: function () {
        that.clearNotificationsListDisplay(type);
        btn.disabled = false;
        btn.innerHTML = "Clear All";
      },
    });
  }

  /**
   * Clears specific notification based on id
   *
   * @param {Event} event the event triggered
   */
  clearNotification(event) {
    event.stopPropagation();
    event.preventDefault();
    var id = event.target.parentElement.dataset.id;

    setupAjax();
    $.ajax({
      url: "/notifications/clear",
      method: "POST",
      data: {
        notification: {
          id: id,
        },
      },
      dataType: "json",
      success: function (data) {
        // Remove notification.
        // Use class so If the user is using the Notifications widget,
        // there will not be duplicate IDs
        $(".notif-" + id).remove();
      },
    });
  }

  /**
   * Pulls the latest notifications from the server to be displayed to the user
   *
   * @param {Event} event the event triggered
   */
  pullLatestNotifications(event) {
    var that = this;

    setupAjax();
    $.ajax({
      url: "/notifications/get_latest",
      method: "POST",
      data: {
        notification: {
          last_update: that.lastNotificationTime,
          receiver_id: that.userId,
        },
      },
      dataType: "json",
      success: function (data) {
        that.lastNotificationTime = data.last_notification_time;
        that.loadLatestNotifications(data.notifications);
      },
    });
  }

  /**
   * Updates the notification to unhighlight it as it will be
   * considered seen/viewed by the user.
   *
   * @param {Event} event the event triggered
   */
  updateClickNotification(event) {
    var id = event.currentTarget.dataset.id;

    setupAjax();
    $.ajax({
      url: "/notifications/update_clicked",
      method: "POST",
      data: {
        notification: {
          id: id,
        },
      },
      dataType: "json",
    });
  }

  /**
   * Loads the latest notifications onto the view and plays a sounds letting the user
   * know that they received a new notification.
   *
   * @param {JSON} notifications the list of notifications, and its data, to be
   *  displayed to the user
   */
  loadLatestNotifications(notifications) {
    var that = this;
    notifications.forEach(function (notification) {
      var notificationHTML = that.generateNotificationHTML(notification);

      that.addNotification(notificationHTML, notification.sort_type);
    });

    let newNotifications = notifications.filter(notif => notif.clicked === false && notif.read_at == null)
    if (that.alertNewNotifs && newNotifications.length > 0) {
      this.playNotificationSound();
    }
  }

  /**
   * Adds the notification into the appropriate lists, if the notification list
   * hasn't been loaded, it will only display the notification badge.
   *
   * @param {String} html the html to be added to the list.
   */
  addNotification(html, sort_type) {
    var that = this;

    switch (sort_type) {
      case that.USER_MESSAGES_TYPE:
        if (that.notifSortNewest) {
          $(that.userMessageListTarget).prepend(html);
        } else {
          $(that.userMessageListTarget).append(html);
        }

        if (that.userMessageLoaded && that.alertNewNotifs) {
          $(that.userBadgeTarget).show();
        }
        that.userMessagesRead = false;
        break;

      case that.TEAM_MESSAGES_TYPE:
        if (that.notifSortNewest) {
          $(that.teamMessageListTarget).prepend(html);
        } else {
          $(that.teamMessageListTarget).append(html);
        }
        if (that.teamMessageLoaded && that.alertNewNotifs) {
          $(that.teamBadgeTarget).show();
        }
        that.teamMessagesRead = false;
        break;

      case that.UPDATES_TYPE:
        if (that.notifSortNewest) {
          $(that.updateListTarget).prepend(html);
        } else {
          $(that.updateListTarget).append(html);
        }
        if (that.updateLoaded && that.alertNewNotifs) {
          $(that.updateBadgeTarget).show();
        }
        that.updatesRead = false;
    }
  }

  /**
   * Loads the notifications for display based off the type of notification
   * and the page number.
   *
   * @param {string} type the type of notification
   * @param {number} page the page to pull notifictions from
   */
  loadNotifications(type, page) {
    var that = this;

    this.showSpinner(type);
    setupAjax();
    $.ajax({
      url: "/notifications?type=" + type + "&page=" + page,
      method: "GET",
      dataType: "json",
      success: function (data) {
        that.hideSpinner(type);
        that.updateNotificationList(type, data);
      },
    });
  }

  /**
   * Updates the notification list based off the type of notification
   *
   * @param {string} type the type of notification
   * @param {json} data the json hash data from the server with notification data
   */
  updateNotificationList(type, data) {
    var notifications = data.notifications;
    var notificationHTML = "";

    console.log(data.pages);

    var that = this;
    notifications.forEach(function (notification) {
      notificationHTML += that.generateNotificationHTML(notification);
    });

    switch (type) {
      case that.USER_MESSAGES_TYPE:
        that.setUserMessageNotifications(notificationHTML, data.pages);
        break;
      case that.TEAM_MESSAGES_TYPE:
        that.setTeamMessageNotifications(notificationHTML, data.pages);
        break;
      case that.UPDATES_TYPE:
        that.setUpdateNotifications(notificationHTML, data.pages);
    }
  }

  /**
   * Updates the notifications to read based off the type passed in.
   *
   * @param {string} type the type of notification
   */
  updateRead(type) {
    var that = this;

    setupAjax();
    $.ajax({
      url: "/notifications/update_read",
      method: "POST",
      data: {
        notification: {
          type: type,
        },
      },
      success: function () {
        that.read(type);
        that.hideBadge(type);
      },
    });
  }

  /**
   * Sets the user message notification list to display to user
   *
   * @param {string} html the notification html to display
   * @param {number} pages the total number of pages for the notifications list
   */
  setUserMessageNotifications(html, pages) {
    $(this.userMessageListTarget).append(html);

    if (pages > 1) {
      var that = this;
      $(this.userMessageListTarget).append(
        that.generateLoadMoreButton(that.USER_MESSAGES_TYPE, pages)
      );
    }

    this.userMessageLoaded = true;
    this.userMessagePage += 1;
  }

  /**
   * Sets the team message notification list to display to user
   *
   * @param {string} html the notification html to display
   * @param {number} pages the total number of pages for the notifications list
   */
  setTeamMessageNotifications(html, pages) {
    $(this.teamMessageListTarget).append(html);

    if (pages > 1) {
      var that = this;
      $(this.teamMessageListTarget).append(
        that.generateLoadMoreButton(that.TEAM_MESSAGES_TYPE, pages)
      );
    }

    this.teamMessageLoaded = true;
    this.teamMessagePage += 1;
  }

  /**
   * Sets the update notification list to display to user
   *
   * @param {string} html the notification html to display
   * @param {number} pages the total number of pages for the notifications list
   */
  setUpdateNotifications(html, pages) {
    $(this.updateListTarget).append(html);

    if (pages > 1) {
      var that = this;
      $(this.updateListTarget).append(
        that.generateLoadMoreButton(that.UPDATES_TYPE, pages)
      );
    }

    this.updateLoaded = true;
    this.updatePage += 1;
  }

  /**
   * Generates the proper html to display the notification
   *
   * @param {json} notification the notification data to render
   * @return {string} the html to be displayed
   */
   generateNotificationHTML(notification) {
   var styleClass = "bg-light-success bg-hover-light";
   if (notification.clicked) {
     styleClass = "";
   }

   var icon = "";
   var type = notification.sort_type;
   var isMessage = type == this.USER_MESSAGES_TYPE || type == this.TEAM_MESSAGES_TYPE ? true : false;
   if (isMessage) {
     icon =  '<div class="col-md-2">' +
               '<img width="55" alt="Profile Image" class="img-fluid rounded-circle mr-2" src="'
               + notification.avatar_url + '" data-toggle="tooltip" title="' + notification.sender_name + '">' +
             '</div>';
   }

   var colSize = isMessage ? 9 : 11;
   var notifHTML = '<form class="dropdown-item notification-card ' + styleClass + ' notif-' + notification.id + '">' +
                     '<div class="row">' +
                       icon +
                       '<div class="col-10 col-md-' + colSize + '">' +
                          '<a class="mb-2" ' +
                          'data-action="click->notifications#updateClickNotification" href="' + notification.object_url + "?selected_channel=" + notification.object_id +'" data-id="' + notification.id + '">' +
                           '<strong class="notification-content" style="white-space: pre-wrap;">' + notification.message + '</strong><br>' +
                           '<p class="notification-content" style="margin-bottom:3.5;"><small>' + notification.date_time + '</small></p>' +
                       '</div>' +
                       '<div class="text-right col-2 col-md-1">' +
                         '<a class="notification-remove text-danger" data-action="notifications#clearNotification" data-id="' + notification.id + '">' +
                           '<i class="mt-5 text-danger cursor-pointer fas fa-minus-circle"></i>' +
                         '</a>' +
                       '</div>' +
                     '</div>' +
                     this.generateMessagePopupNotification(notification) +
                   '</form>';

   return notifHTML;
 }

  /**
   * Generates the proper html to display the extra message popup required
   * for certain notifications.
   *
   * @param {json} notification the notification data to render
   * @return {string} the html to be displayed
   */
  generateMessagePopupNotification(notification) {
    var messagePopupType = 29;
    var extensionDeniedPopupType = 40;
    var oneDayLeftToPay = 47;


    if (notification.notification_type == messagePopupType ||
        notification.notification_type == extensionDeniedPopupType ||
        notification.notification_type == oneDayLeftToPay) {
      return  '<div class="row mb-3">' +
                '<div class="col-md-12">' +
                  '<button data-sendername="' + notification.sender_name + '" data-message="' + notification.description_message +
                  '" data-action="interactions#openNotifMessageModal" type="button" class="btn btn-sm btn-primary" style="margin-top: -7px;">View Message</button>' +
                '</div>' +
              '</div>';
    }

     return "";
   }

  /**
   * Generates the load more button for the notifications list,
   * used to load more notification, if available.
   *
   * @param {string} type the type of notification
   * @param {number} pages the total number of pages for the notifications list
   * @return {string} the html to display
   */
   generateLoadMoreButton(type, pages) {
     if (this.getPage(type) >= pages) {
       return "";
     }

     var html =  '<div>' +
                   '<div class="col-md-12 text-center mt-3 mb-3">' +
                     '<button type="button" class="btn btn-light" data-action="notifications#loadMore" data-type="' + type + '">Load More</button>' +
                   '</div>' +
                 '</div>';

     return html;
   }

  /**
   * Gets the current page for the notification type
   *
   * @param {string} type the type of notification
   * @return {number} the current page of the notification type
   */
  getPage(type) {
    if (type == this.USER_MESSAGES_TYPE) {
      return this.userMessagePage;
    } else if (type == this.TEAM_MESSAGES_TYPE) {
      return this.teamMessagePage;
    } else {
      return this.updatePage;
    }
  }

  /**
   * Checks to see if the notification type has been loaded already
   *
   * @param {string} type the type of notification
   * @return {boolean} true if notification type loaded, false otherwise
   */
  isLoaded(type) {
    if (type == this.USER_MESSAGES_TYPE) {
      return this.userMessageLoaded;
    } else if (type == this.TEAM_MESSAGES_TYPE) {
      return this.teamMessageLoaded;
    } else {
      return this.updateLoaded;
    }
  }

  /**
   * Checks to see if the notifications have been read by the user based off the
   * notification type
   *
   * @param {string} type the type of notification
   * @return {boolean} true if notification list type read, false otherwise
   */
  hasRead(type) {
    if (type == this.USER_MESSAGES_TYPE) {
      return this.userMessagesRead;
    } else if (type == this.TEAM_MESSAGES_TYPE) {
      return this.teamMessagesRead;
    } else {
      return this.updatesRead;
    }
  }

  /**
   * Hides the notification badge (red dot) that displays when
   * there are unread notifications
   *
   * @param {string} type the type of notification
   */
  hideBadge(type) {
    var that = this;
    switch (type) {
      case that.USER_MESSAGES_TYPE:
        $(that.userBadgeTarget).hide();
        break;
      case that.TEAM_MESSAGES_TYPE:
        $(that.teamBadgeTarget).hide();
        break;
      case that.UPDATES_TYPE:
        $(that.updateBadgeTarget).hide();
    }
  }

  /**
   * Shows the notification badge (red dot) that displays when
   * there are unread notifications
   *
   * @param {string} type the type of notification
   */
  showBadge(type) {
    var that = this;
    switch (type) {
      case that.USER_MESSAGES_TYPE:
        $(that.userBadgeTarget).show();
        break;
      case that.TEAM_MESSAGES_TYPE:
        $(that.teamBadgeTarget).show();
        break;
      case that.UPDATES_TYPE:
        $(that.updateBadgeTarget).show();
    }
  }

  /**
   * Shows the loading spinner so user is aware that the notifications are loading
   *
   * @param {string} type the type of notification
   */
  showSpinner(type) {
    var that = this;
    switch (type) {
      case that.USER_MESSAGES_TYPE:
        $(that.userMessageListTarget).append(spinner());
        break;
      case that.TEAM_MESSAGES_TYPE:
        $(that.teamMessageListTarget).append(spinner());
        break;
      case that.UPDATES_TYPE:
        $(that.updateListTarget).append(spinner());
    }
  }

  /**
   * Hides the loading spinner, called once data is loaded.
   *  Expects the spinner to be the last element of the notif list.
   *
   * @param {string} type the type of notification
   */
  hideSpinner(type) {
    var that = this;
    switch (type) {
      case that.USER_MESSAGES_TYPE:
        $(that.userMessageListTarget).children().last().remove();
        break;
      case that.TEAM_MESSAGES_TYPE:
        $(that.teamMessageListTarget).children().last().remove();
        break;
      case that.UPDATES_TYPE:
        $(that.updateListTarget).children().last().remove();
    }
  }

  /**
   * Sets the read status to the notification type to true based off the
   * notification type passed in
   *
   * @param {string} type the type of notification
   */
  read(type) {
    var that = this;
    switch (type) {
      case that.USER_MESSAGES_TYPE:
        that.userMessagesRead = true;
        break;
      case that.TEAM_MESSAGES_TYPE:
        that.teamMessagesRead = true;
        break;
      case that.UPDATES_TYPE:
        that.updatesRead = true;
    }
  }

  /**
   * Clears the div containing the list of notifications
   *
   * @param {string} type the type of notification
   */
  clearNotificationsListDisplay(type) {
    var that = this;
    switch (type) {
      case that.USER_MESSAGES_TYPE:
        that.userMessageListTarget.innerHTML = "";
        break;
      case that.TEAM_MESSAGES_TYPE:
        that.teamMessageListTarget.innerHTML = "";
        break;
      case that.UPDATES_TYPE:
        that.updateListTarget.innerHTML = "";
    }
  }

  /**
   * Plays the notification sound
   *
   * Set Minimum time between notifications
   */
  playNotificationSound() {
    let audio = new Audio("/audios/notification.wav");
    audio.volume = 0.05;

    // 5 min * 60 s * 1000ms = 5 min timeout
    let timeout = (5*60*1000);

    // Delay notification sound until second fetch, and throttle
    if (this.playSound == 'true' && window.lastNotificationSoundTime + timeout < Date.now()) {
      audio.play().catch(function (error) {
        console.log(error);
      });

      window.lastNotificationSoundTime = Date.now();
    } else  if (!window.lastNotificationSoundTime) {
      window.lastNotificationSoundTime = 0;
    } else {
      console.log('throttle notification sound')
    }
  }
}
