import { Controller } from "stimulus";

const COUNTER_ID = "#comment-length-warning";
const INPUT_ID = "#doc_comment_text";
const MIN_LENGTH = 5;
const TOO_LONG_MESSAGE = "<span style='color:red'><b>Comment is too long!</b></span>";
const TOO_SHORT_MESSAGE = "<b>Comment is too short.</b>";

const countCommentLength = (input) => {
  let length = input.value.length;
  let page = input.parentElement;
  let maxLength = window.maxLength

  let charLeft = maxLength - length;

  if (charLeft > (maxLength - MIN_LENGTH)) {
    page.querySelector(COUNTER_ID).innerHTML =
      TOO_SHORT_MESSAGE + ` At least <b>${charLeft - (maxLength - MIN_LENGTH)}</b> `;
    input.form.querySelector("button").disabled = true;
  } else if (charLeft >= 0) {
    page.querySelector(COUNTER_ID).innerHTML = `<b>${charLeft.toString()}</b>`;
    input.form.querySelector("button").disabled = false;
  } else {
    page.querySelector(COUNTER_ID).innerHTML = TOO_LONG_MESSAGE + " No ";
    input.form.querySelector("button").disabled = true;
  }
};

export default class extends Controller {
  connect() {
    window.maxLength = this.element.getAttribute("data-max-length");

    this.element
      .querySelector(INPUT_ID)
      .addEventListener("input", this.validateInput);
  }

  disconnect() {
    this.element
      .querySelector(INPUT_ID)
      .removeEventListener("input", this.validateInput);
  }

  validateInput() {
    countCommentLength(this);
  }
}
