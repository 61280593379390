import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['rateLabel', 'rateInput', 'wordCountGroup', 'fixedInput'];

  /**
    * Changes & hides labels/inputs to display only necessary fields 
    * in the editor contact form
  */
  fixedContract() {
    const wordCountGroup = this.wordCountGroupTarget
    const rateLabel = this.rateLabelTarget
    const rateInput = this.rateInputTarget
    const fixed = this.fixedInputTarget.value === 'true'

    if (fixed) {
      wordCountGroup.style.display = 'none'
      rateLabel.innerText = 'Fixed Rate'
      rateInput.placeholder = 'Enter trainee rate'
    } else {
      wordCountGroup.style.display = 'block'
      rateLabel.innerText = 'Rate'
      rateInput.placeholder = 'Enter rate per 1000 words (ex. 0.80)'
    }
  }
}
