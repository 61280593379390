import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['paymentType', 'payoneerField', 'paypalText', 'noPaymentTypeText', 'paypalConnectButton']

  connect() {
    this.togglePaymentFields()
  }

  togglePaymentFields() {
    this.payoneerFieldTargets.forEach((field) => {
      if (this.paymentTypeTarget.value !== 'payoneer') {
        field.disabled = true
        field.classList.add('d-none')
      } else {
        field.disabled = false
        field.classList.remove('d-none')
      }
    })

    this.paypalTextTargets.forEach((text) => {
      if (this.paymentTypeTarget.value !== 'paypal') {
        text.classList.add('d-none')
      } else {
        text.classList.remove('d-none')
      }
    })

    if (this.paymentTypeTarget.value === 'paypal') {
      this.paypalConnectButtonTarget.classList.remove('d-none')
    } else {
      this.paypalConnectButtonTarget.classList.add('d-none')
    }
  }
}
