// Load all the controllers within this directory and all subdirectories.
// Controller files must be named *_controller.js.

import { Application } from "stimulus"
import { definitionsFromContext } from "stimulus/webpack-helpers"
import TextareaAutogrow from "stimulus-textarea-autogrow"
import Timeago from "stimulus-timeago"

const application = Application.start()

application.register("textarea-autogrow", TextareaAutogrow)
application.register("timeago", Timeago)

const context = require.context("controllers", true, /_controller\.js$/)

// Include Component Controllers
const componentContext = require.context("../../components", true, /_controller\.js$/)

application.load(
    definitionsFromContext(context).concat(definitionsFromContext(componentContext))
)
