import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['postingField', 'submitButton']

  connect() {
    let url = `/customer/dashboard/customer_management`

    setupAjax();
    $.ajax({ url: url, method: "GET" })
  }
}
