import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ["copyToClipboardCopy", "confirmCopyPopup"]

  copy() {
    let text = this.copyToClipboardCopyTarget.innerText;
    let copyPopup = this.confirmCopyPopupTarget;

    navigator.clipboard.writeText(text).then(function() {
      copyPopup.classList.remove('hidden');

      setTimeout(function() {
        copyPopup.classList.add('hidden');
      }, 2000);
    });
  }
}
