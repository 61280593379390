import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [
    "orders", "userId", "customerId", "user", 'inviteType', 'inviteTypeField', 'orderWarning',
    'approvalMessage', 'areYouSure', 'textArea', 'yesButton'
  ]

  connect() {
    console.log("Hire is now connected");
  }

  initialize() {
    this.inviteTypeDescriptions = JSON.parse(this.inviteTypeFieldTarget.dataset.inviteTypeDescriptions)
    this.inviteTypeApprovalMessages = JSON.parse(this.inviteTypeFieldTarget.dataset.inviteTypeApprovalMessages)

    this.updateInviteTypeDescription()
  }

  retrieveOrdersForUser(event) {
    var userId = event.target.dataset.userid
    var customerId = event.target.dataset.customerid
    let inviteType = event.target.value;
    let that = this;
    // Clear current options
    $(this.ordersTarget).empty();

    // Set user ID
    $(this.userTarget).val(userId);

    setupAjax();
    $.ajax({
      url: "/hire_order_invites/available_orders",
      method: "POST",
      data: {
        hire_order_invite: {
          user_id: userId,
          customer_id: customerId,
          invite_type: inviteType
        }
      },
      dataType: "json",
      success: function (data) {
        var orders = JSON.parse(data.orders);
        console.log(JSON.parse(data.orders));
        that.addEmptyOption();
        $(that.orderWarningTarget).html(that.inviteMessage());

        if (orders.length > 0) {
          // Adding an empty option because safari selects the first option by default if its was 
          // disabled causing a crash
          orders.forEach(function (order) {
            that.addOrderOption(order[1], order[0], order[2]);
          });
        }
      }
    });
  }

  addOrderOption(value, text, disable) {
    let controller = this;
    let option;
    if (!disable) {
      option = "<option value=" + value + ">" + text + "</option>";
    } else {
      option = "<option class='text-muted' value=" + value + " disabled>" + text + "</option>";
      controller.addEmptyMessage(controller.ordersTarget);
    }
    $(this.ordersTarget).append(option);
  }

  // This method adds a black option for safari browser to default to
  addEmptyOption() {
    let option;
    option = "<option value=''>Please select an option</option>";
    $(this.ordersTarget).append(option);
  }

  addEmptyMessage(elTarget) {
    let controller = this;
    if (controller.emptyMessageAdded) {
      return
    }

    controller.emptyMessageAdded = true;
  }

  updateInviteTypeDescription() {
    this.inviteTypeTarget.innerText = this.inviteTypeDescriptions[this.inviteTypeFieldTarget.value]
    this.approvalMessageTarget.innerText = this.inviteTypeApprovalMessages[this.inviteTypeFieldTarget.value]

    if (this.inviteTypeTarget.textContent == 'undefined') {
      this.inviteTypeTarget.innerText = 'Please select an invite type.'
    }

    if (this.inviteTypeFieldTarget.value == '' || this.ordersTarget.value == '' || this.textAreaTarget.value == '') {
      this.approvalMessageTarget.innerText = 'Please finish filling out the form.'
      this.areYouSureTarget.innerText = ''
      this.yesButtonTarget.disabled = true
    } else {
      this.areYouSureTarget.innerText = 'Are you sure you want to continue?'
      this.yesButtonTarget.disabled = false
    }
  }

  inviteMessage() {
    return (
      "If your project is grayed out or absent from the list, it may be due to one or more of the following reasons:" +
      "<ul>" +
        "<li>It is already assigned or On Hold (or a status that prevents assignment)</li>" +
        "<li>The freelancer doesn't qualify for the project's niche, package tier, or project type</li>" +
        "<li>The freelancer already has a pending invite</li>" +
      "</ul>" +
      "Please select another freelancer based on these applicable tags. If you require further assistance, please contact our Support Team."
    );
  }
}
