import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["amountOfDays", "expiration", "writingSample"];

  changeRequestExpiration(event) {
    var expire = event.target.value;

    if (expire == "true") {
      this.amountOfDaysTarget.classList.remove("d-none");
      this.expirationTarget.setAttribute("required", "");
    } else {
      this.amountOfDaysTarget.classList.add("d-none");
      this.expirationTarget.removeAttribute("required");
    }
  }

  writingSample(event) {
    var requestID = event.target.dataset.request;
    var that = this;
    setupAjax();
    $.ajax({
      url: "/requests/writing_sample",
      method: "GET",
      data: {
        order_request: {
          id: requestID
        }
      },
      dataType: "json",
      success: function (data) {
        that.writingSampleTarget.innerHTML = data.sample;
      }
    });
  }
}
