import { Controller } from "stimulus";
import countries from "../../../public/countries.json";

export default class extends Controller {
  static targets = ["country"];

  connect() {
    this.loadRegions();
  }

  // loads all available regions when user selects a country
  loadRegions() {
    var selectedCountry = this.countryTarget.value;
    var countryData = null;
    var regions = [];
    var regionSelect = $("#customer_attributes_region");

    if (!selectedCountry) {
      return;
    }

    for (var c = 0; c < countries.length; c++) {
      if (countries[c].name === selectedCountry) {
        countryData = countries[c];
        break;
      }
    }

    regions = countryData.provinces;
    regionSelect.find("option").remove().end(); // clear dropdown
    if (regions != 0) {
      regionSelect.append($("<option />").val("").text(""));
      //populate dropdown with regions
      regions.forEach((region) => {
        regionSelect.append(
          $("<option />").val(region.province).text(region.province)
        );
      });
      regionSelect.val(this.countryTarget.dataset.region);
      regionSelect.prop("required", true);
      regionSelect.addClass("block");
      $("#region-block").removeClass("d-none");
    } else {
      //hide dropdown
      regionSelect.prop("required", false);
      regionSelect.removeClass("block");
      $("#region-block").addClass("d-none");
    }
  }
}
