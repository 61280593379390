import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ["fileInput"]

  connect() {
    this.updateTestFileName()
  }

  updateTestFileName() {
    const fileInput = this.fileInputTarget

    if (fileInput.files.length > 0) {
      document.getElementById("fileNameTestAns").innerHTML = fileInput.files[0].name
    } else {
      document.getElementById("fileNameTestAns").innerHTML = "No File Chosen"
    }
  }
}
