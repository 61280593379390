import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ['sampleWriting', 'bookWriting']

  packageChanged(event) {
    if (this.sampleWritingTarget.contains(event.target)) {
      if (this.bookWritingTarget.classList.contains('active_check_box')) {
        this.bookWritingTarget.click()
        event.target.click()
      }
    }

    if (this.bookWritingTarget.contains(event.target)) {
      if (this.sampleWritingTarget.classList.contains('active_check_box')) {
        this.sampleWritingTarget.click()
        event.target.click()
      }
    }
  }
}
