import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "customer", "customerDropDown", "package", "packageDropDown" ]

  
  changedDiscountType(event){
    if (event.target.value == 'product') {
      this.packageTarget.classList.remove('d-none');
    }
    else {
      this.packageTarget.classList.add('d-none');
      this.packageDropDownTarget.value = '';
    }

    if (event.target.value == 'customer') {
      this.customerTarget.classList.remove('d-none');
    } else {
      this.customerTarget.classList.add('d-none');
      this.customerDropDownTarget.value = '';
    }
  }
}