import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [
    'hours', 'discountCode', 'discountValue', 'discountType', 'discountError',
    'discountDollarSymbol', 'discountPercentSymbol'
  ]

  connect() {
    this.updatePricing();
  }

  addDiscount() {
    let value;
    let type;
    let error;
    let oldRates;

    setupAjax();
    $.ajax({
      url: "/out_of_scope_orders/discount",
      method: "GET",
      async: false,
      data: {
        code: this.discountCodeTarget.value,
        hours: this.hoursTarget.value
      },
      success: function(data) {
        value = data.value
        type = data.type
        error = data.error      }
    })

    this.discountValueTarget.innerText = value
    this.discountErrorTarget.innerText = error
    this.discountTypeTarget.innerText = type

    if (type == 'fixed') {
      this.discountDollarSymbolTarget.classList.remove('d-none')
      this.discountPercentSymbolTarget.classList.add('d-none')
    } else {
      this.discountDollarSymbolTarget.classList.add('d-none')
      this.discountPercentSymbolTarget.classList.remove('d-none')
    }

    this.updatePricing()
  }

  removeDiscount() {
    this.discountCodeTarget.value = ''
    this.discountValueTarget.innerText = 0
    this.discountErrorTarget.innerText = ''
    this.discountTypeTarget.innerText = 'N/A'
    this.discountPercentSymbolTarget.classList.add('d-none')
    this.discountDollarSymbolTarget.classList.add('d-none')

    this.updatePricing()
  }

  updatePricing() {
    setupAjax();
    $.ajax({
      url: "/job_posting/cost_calculator/calculate_json/",
      method: "POST",
      data: {
        'posting_div': {
          "posting_type": "out_of_scope_posting", "hours": this.hoursTarget.value
        },
        'discount_value': this.discountValueTarget.innerText,
        'discount_type': this.discountTypeTarget.innerText
      },
      success: function(data) {
        $('#total_costs').html(data.total)
        $('#discounts').html(data.discount)
        $('#total_discounted_costs').html(data.total_with_discount)
      }
    })
  }
}
