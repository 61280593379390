import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["active", "scheduled"]

  connect() {
    this.displayFirstLoginAlert();
    this.displayDelayAlert();
    this.displayBetaFeatureAccess();
    this.displayNewFeatureAccess();
    this.displayFreelancerQuestionsAlert();

    if (document.referrer == this.data.get('url')) {
      this.displayUrbaniteActivateAccountAlert();
    }
  }

  displayDelayAlert() {
    var that = this;

    setupAjax();
    $.ajax({
      url: "/alerts/delay",
      method: "GET",
      dataType: "json",
      success: function (data) {
        that.activeTarget.innerHTML = data.active_count;
        that.scheduledTarget.innerHTML = data.scheduled_count;

        if (data.display) {
          $("#alertDelayModal").modal("show");
        }
      }
    });
  }

  displayBetaFeatureAccess() {
    var that = this;

    setupAjax();
    $.ajax({
      url: "/alerts/feature",
      method: "GET",
      dataType: "json",
      success: function (data) {
        if (data.display) {
          $("#betaFeatureModal").modal("show");
        }
      }
    });
  }

  displayUrbaniteActivateAccountAlert() {
    var that = this;

    setupAjax();
    $.ajax({
      url: "/alerts/activate_urbanite_account",
      method: "GET",
      dataType: "json",
      success: function (data) {
        if (data.display) {
          $("#activateUrbaniteAccount").modal("show");
        }
      }
    });
  }

  displayNewFeatureAccess() {
    var that = this;

    setupAjax();
    $.ajax({
      url: "/alerts/new_feature",
      method: "GET",
      dataType: "json",
      success: function (data) {

        if (data.display) {
          $("#newFeatureModal").modal("show");
        }
      }
    });
  }

  displayFreelancerQuestionsAlert() {
    var that = this;

    setupAjax();
    $.ajax({
      url: "/alerts/freelancer_questions",
      method: "GET",
      dataType: "json",
      success: function (data) {
        if (data.display) {
          $("#alertFreelancerQuestionsModal").modal("show");
        }
      }
    });
  }

  displayFirstLoginAlert() {
    setupAjax();
    $.ajax({
      url: "/alerts/first_sign_in",
      method: "GET",
      dataType: "json",
      success: function (data) {

        if (data.display) {
          $("#firstSignInModal").modal("show");
          $('#testing').attr('src', data.video);
          $("#testing")[0].load();
        }
      }
    });
  }
}
